import React from 'react';

import TitleList from "../common/title-list";
import { Link } from 'react-router-dom';
import constants from "../../lib/constants"
import PubSub from "pubsub-js"

function HomeOtherList({ title, contentList, detailLink, requireLogin = false }) {

    const handleClick = (e) => {
        if (requireLogin) {
            e.preventDefault()
            PubSub.publish("REQUIRE_LOGIN", e.target.to)
        }
    }

    return (
        <div>
            <TitleList titleData={title} />
            <div className="row">
                {
                    contentList?.map((content) => (
                        <div className="col-xl-4 col-md-6" key={content.id}>
                            <Link to={`${detailLink}/${content.id}`} onClick={handleClick}>
                                <div className="banner-content_pic"><img src={`${constants.imageBaseUrl}/product/${content.image}`} alt="" /></div>
                                <div className="banner-content_text"><p>{content.name}</p></div>
                            </Link>
                        </div>
                    ))
                }
            </div>
        </div>
    );
}

export default HomeOtherList;