import React, { useEffect, useState } from "react"
import { useLocation } from "react-router-dom"

import Filter from '../component/common/filter';
import ListItem from '../component/list/list-item';
import Pagination from '../component/common/pagination';
import FilterIcon from '../component/common/filter-icon';
import Title from '../component/common/title';

import iconListen from "../img/icon_listen.svg"
import iconHead from "../img/icon_head.svg"

import apiService from "../service/apiService"
import constants from "../lib/constants"

const list = [
  { pic: iconListen, name: "最新音檔", category: "latest" },
  { pic: iconListen, name: "繼續聆聽", category: "history" },
  { pic: iconListen, name: "熱門音檔", category: "hot" },
  { pic: iconHead, name: "我的最愛", category: "like" },
]

function ListenContent() {
  const [pageTitle, setPageTitle] = useState(list[0])
  const [products, setProducts] = useState([])
  const [pagination, setPagination] = useState({
    pageNumber: 1,
    pageSize: constants.contentPageSize,
    totalPages: 1,
    first: true,
    last: false,
  })
  const [pageNumber, setPageNumber] = useState(1)
  const [keyword, setKeyword] = useState("")
  const [order, setOrder] = useState("desc")

  const search = useLocation().search
  let category = new URLSearchParams(search).get("category") ?? 'latest'

  useEffect(() => {
    const tt = list.find((t) => t.category === category)
    setPageTitle(tt)
    const fetchData = async () => {
      // console.log(`keyword = ${keyword}`)
      let params = {
        productType: constants.productType.listen,
        page: pageNumber,
        size: constants.contentPageSize,
        order: order,
        keyword: keyword
      }
      let pageData = {}
      if (category === "history") {
        params.sortBy = "updatedAt"
        pageData = await apiService.apiGetHistory(params)
        const products = pageData.content.map((history) => history.product)
        setProducts(products)
      } else {
        if (category === "hot") {
          params.sortBy = "visitCount"
        } else if (category === "like") {
          params.likeOnly = true
        } else {
          // latest
          params.sortBy = "id"
        }
        pageData = await apiService.apiProducts(params)
        setProducts(pageData.content)
      }
      setPagination({
        pageNumber: pageData.number + 1,
        pageSize: pageData.size,
        totalPages: pageData.totalPages,
        first: pageData.first,
        last: pageData.last,
      })
    }
    fetchData()
  }, [pageNumber, category, order, keyword])

  const handleSelectPage = (pageNumber) => {
    setPageNumber(pageNumber)
  }

  return (
    <div className="container">
      <div className="d-flex align-items-center justify-content-between title_content">
        <Title data={pageTitle} />
        <div className="d-md-none d-block">
          <FilterIcon 
            category={category} 
            keyword={keyword}
            order={order}
            onChangeKeyword={setKeyword} 
            onChangeMonth={setKeyword}
            onChangeOrder={setOrder} />
        </div>
      </div>
      <div className="d-flex flex-wrap content_main">
        <div className="content_filter d-md-block d-none">
        <Filter 
          category={category} 
          keyword={keyword}
          order={order}
          onChangeKeyword={setKeyword} 
          onChangeMonth={setKeyword}
          onChangeOrder={setOrder} />
        </div>
        <div className="content_list">
          <div className="content_list_inner">
            <div className="row">
              {products.map((product) => (
                <div className="col-md-6" key={product.id}>
                  <ListItem content={product} detailLink={'/listen-inner'} />
                </div>
              ))}
            </div>
          </div>
          <Pagination pagination={pagination} onSelectPage={handleSelectPage} />
        </div>
      </div>
    </div>
  )
}

export default ListenContent
