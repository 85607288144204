import { useEffect } from "react";
import { useLocation } from "react-router-dom";
import ReactGA from "react-ga4";

const usePageTracking = () => {
  const location = useLocation();

  useEffect(() => {
    ReactGA.initialize("G-TEWWFGTBZG");
    const path = location.pathname + location.search
    ReactGA.send({ hitType: "pageview", page: path });
    // console.log('tracking ' + path)
  }, [location]);
};

export default usePageTracking;