import React from 'react';
import { Routes, Route } from 'react-router-dom';

import Category from '../page/category';
import Home from '../page/home';
import Listen from '../page/listen';
import Watch from '../page/watch';
import Read from '../page/read';
import ListenContent from '../page/listen-content';
import WatchContent from '../page/watch-content';
import MemberFavorite from '../page/member-favorite';
import MemberRecord from '../page/member-record';
import MemberAccount from '../page/member-account';
import ListenInner from '../page/listen-inner';
import WatchInner from '../page/watch-inner';
import ReadInner from '../page/read-inner';
import Term from '../page/term';
import NotFoundPage from '../page/404';
import ScrollToTop from '../lib/ScrollToTop';
import usePageTracking from '../hooks/usePageTracking';
import HomeNew from '../page/home-new';
import Lesson from '../page/lesson';
import Cart from '../page/cart';
import MemberMylesson from '../page/member-mylesson';
import LatestLesson from '../page/latest-lesson';
import LessonContent from '../page/lesson-content';
import Classroom from '../page/classroom';
import Qa from '../page/qa';
import MemberEmpty from '../page/member-empty';
import MemberDiscount from '../page/member-discount';
import Order from '../page/order';
import Service from '../page/service';
import About from '../page/about';
import HomeNewLogin from '../page/home-new-login';
import MemberFavoriteStatic from '../page/member-favorite-static-edit';
import MemberRecordStatic from '../page/member-record-static-new';
import MemberAccountStatic from '../page/member-account-static-edit';
import Privacy from '../page/privacy';
import LineAuth from '../page/line-auth';
import TellypenInstructions from '../page/tellypen-instructions';

function RouteView() {
    usePageTracking();
    return (
        <ScrollToTop>
            <Routes>
                <Route path="/category" element={<Category />} />
                <Route index element={<HomeNew />} />
                <Route path="/listen" element={<Listen />} />
                <Route path="/watch" element={<Watch />} />
                <Route path="/read" element={<Read />} />
                <Route path="/listen-content" element={<ListenContent />} />
                <Route path="/watch-content" element={<WatchContent />} />
                <Route path="/listen-inner/:id" element={<ListenInner />} />
                <Route path="/watch-inner/:id" element={<WatchInner />} />
                <Route path="/read-inner/:id" element={<ReadInner />} />
                {/* <Route path="/member-favorite" element={<MemberFavorite />} /> */}
                <Route path="/member-favorite" element={<MemberFavoriteStatic />} />
                <Route path="/member-record" element={<MemberRecord />} />
                <Route path="/member-account" element={<MemberAccount />} />
                <Route path="/term" element={<Term />} />
                <Route path="/404" element={<NotFoundPage />} />
                <Route path="/home-new" element={<HomeNew />} />
                <Route path="/lesson" element={<Lesson />} />
                <Route path="/cart" element={<Cart />} />
                <Route path="/member/mylessons" element={<MemberMylesson />} />
                <Route path="/latest-lesson" element={<LatestLesson />} />
                <Route path="/lesson-content/:id" element={<LessonContent />} />
                <Route path="/classroom/:id" element={<Classroom />} />
                <Route path="/qa" element={<Qa />} />
                <Route path="/member-empty" element={<MemberEmpty />} />
                <Route path="/member-discount" element={<MemberDiscount />} />
                <Route path="/order" element={<Order />} />
                <Route path="/service" element={<Service />} />
                <Route path="/about" element={<About />} />
                <Route path="/home-new-login" element={<HomeNewLogin />} />
                <Route path="/member-favorite-static-edit" element={<MemberFavoriteStatic />} />
                <Route path="/member-record-static-edit" element={<MemberRecordStatic />} />
                <Route path="/member-account-static-edit" element={<MemberAccountStatic />} />
                <Route path="/privacy" element={<Privacy />} />
                <Route path="/line-auth" element={<LineAuth />} />
                <Route path="/tellypen-instructions" element={<TellypenInstructions />} />
            </Routes>
        </ScrollToTop>
    );
}

export default RouteView;
