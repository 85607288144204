import React from "react"
import { Navigation, Pagination, Autoplay, A11y } from "swiper"

import { Swiper, SwiperSlide } from "swiper/react"

import "swiper/css"
import "swiper/css/navigation"
import "swiper/css/pagination"
import "swiper/css/scrollbar"
import constants from "../../lib/constants"

function ListBanner({ banners }) {
  return (
    <Swiper
      className="banner banner-list"
      modules={[Navigation, Pagination, Autoplay, A11y]}
      spaceBetween={50}
      slidesPerView={1}
      speed={1000}
      loop={true}
      autoplay={{
        delay: 10000,
        disableOnInteraction: false,
      }}
      pagination={{ clickable: true }}
    // onSwiper={(swiper) => console.log(swiper)}
    >
      {banners.map((banner) => (
        <SwiperSlide key={banner.id}>
          <img className="banner_pic" src={`${constants.imageBaseUrl}/banner/${banner.image}`} alt="" />
          <img className="banner_pic-m" src={`${constants.imageBaseUrl}/banner/${banner.imageMobile}`} alt="" />
        </SwiperSlide>
      ))}
    </Swiper>
  )
}

export default ListBanner
