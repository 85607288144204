import React, { useEffect, useState } from 'react';
import { Link } from "react-router-dom";
import IconCrossGray from '../../img/icon_cross_gray.svg'
import constants from '../../lib/constants';
import apiService from '../../service/apiService';

function ListItemFavorite({ data, handleDelete }) {
    /* // data:
        {
            "collectionId": 7,
            "seriesId": 42,
            "image": "330.6_186.2px-lesson list product.jpg",
            "title": "如何用音樂說故事：許惠鈞的音樂童話",
            "date": "2024-02-02"
        }
    */

    const [linkTo, setLinkTo] = useState('')
    useEffect(() => {
        if (data.seriesId) {
            setLinkTo(`/lesson-content/${data.seriesId}`)
        }
    }, [data.seriesId])

    return (
        <div className="list_content_item item-favorite">
            <Link to={linkTo} className='d-flex align-items-center'>
                <div className="list_content_pic">
                    <img src={`${constants.imageBaseUrl}/${data.image}`} alt="" />
                </div>
                <div className="list_content_text">
                    <p className="list_content_title">{data.title}</p>
                    <p className="list_content_date">{data.date}</p>
                </div>
            </Link>
            <button className="btn-icon btn-bg-white"
                onClick={() => handleDelete(data.collectionId)}
            ><img src={IconCrossGray} alt="" /></button>
        </div>
    );
}

export default ListItemFavorite;