import React from 'react';

function NotFoundPage({code = '404', message = '您找的資源不存在'}){
    return (
        <div className='container term'>
            <h1 className='title_article'>{code}</h1>
            <div className='term_item'>
            <h1 className='title_article title_article-third'>{message}</h1>
        </div>
        </div>
    );
}

export default NotFoundPage;