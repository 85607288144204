import React, { useEffect, useState } from "react"

import ListBanner from "../component/list/list-banner"
import ListContent from "../component/list/list-content"

import iconWatch from "../img/icon_watch_color.svg"
import iconHead from "../img/icon_head.svg"

import apiService from "../service/apiService"
import constants from "../lib/constants"
import { Default } from "react-awesome-spinners"

const list = [
  { icon: iconWatch, title: "最新影片", category: "latest" },
  { icon: iconWatch, title: "繼續觀看", category: "history" },
  { icon: iconWatch, title: "熱門影片", category: "hot" },
  { icon: iconHead, title: "我的最愛", category: "like" },
]
function Watch() {
  const [banners, setBanners] = useState([])
  const [latest, setLatest] = useState([])
  const [history, setHistory] = useState([])
  // const [hot, setHot] = useState([])
  const [like, setLike] = useState([])
  const [loading, setLoading] = useState(false)

  useEffect(() => {
    const fetchData = async () => {
      try {
        setLoading(true)
        const banners = await apiService.apiHomeBanners()
        setBanners(banners)
        const page = await apiService.apiPage({
          type: constants.productType.watch,
        })
        setLatest(page.latest.content)
        setHistory(page.history.content.map((history) => history.product))
        // setHot(page.hot.content)
        setLike(page.like.content)
      } catch (e) {
      } finally {
        setLoading(false)
      }
    }
    fetchData()
  }, [])
  return (
    <>
      {loading && (
        <div className="loader">
          <Default color="black" />
          <p>讀取中</p>
        </div>
      )}
      {!loading && (
        <div>
          <ListBanner banners={banners.filter((b) => b.location === "WATCH")} />
          <div className="container">
            <ListContent
              section={list[0]}
              contentList={latest}
              showAllLink={"/watch-content"}
              detailLink={"/watch-inner"}
            />
            <ListContent
              section={list[1]}
              contentList={history}
              showAllLink={"/watch-content"}
              detailLink={"/watch-inner"}
            />
            {/* <ListContent section={list[2]} contentList={hot} showAllLink={'/watch-content'} detailLink={'/watch-inner'} /> */}
            <ListContent
              section={list[3]}
              contentList={like}
              showAllLink={"/watch-content"}
              detailLink={"/watch-inner"}
            />
          </div>
        </div>
      )}
    </>
  )
}

export default Watch
