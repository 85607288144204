import React from 'react';

import IconArrowWhite from '../../img/icon_arrow-white.svg';

function FilterMonth({onChange}){
  const list = [1,2,3,4,5,6,7,8,9,10,11,12]
    return (
        <div>
            <label className='input_label' htmlFor="">月份篩選</label>
            <div className="input">
                <div className="input_content"></div>
                <div className="input_btn"><img src={IconArrowWhite} alt="" /></div>
                <select className='input_select' defaultValue={""} onChange={(e) => onChange(e.target.value)} >
                    <option value="" disabled>請選擇月份</option>
                    {list.map(m => <option key={m} value={`${m}/`}>{`${m}月`}</option>)}
                </select>
            </div>
        </div>
    );
}

export default FilterMonth;