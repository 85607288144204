import React, { useEffect, useState } from "react"
import { useLocation } from "react-router-dom"

// import Filter from "../component/common/filter"
import Pagination from "../component/common/pagination"
// import FilterIcon from "../component/common/filter-icon"
import Title from "../component/common/title"
import IconEmpty from '../img/icon_empty.svg';
import ListItemRecord from "../component/list/list-item-record"
import MemberExchange from "../component/member/member-exchange"

import iconHead from "../img/icon_head.svg"
import Exchange from "../component/popup/exchange"
import PopupLoginTemplate from "../component/popup/popup-login-template"
import PopupSuccess from "../component/popup/popup-success"

import apiService from "../service/apiService"
import constants from "../lib/constants"
import MemberNav from "../component/member/member-nav"

const PageTitle = { pic: iconHead, name: "課程兌換", parent: "會員專區" };
const NavActiveIndex = 5;

function MemberRecord() {
  const search = useLocation().search
  const redeem = new URLSearchParams(search).get("redeem") ?? false
  const [buttonRedeemPopup, setButtonRedeemPopup] = useState(redeem)
  const [showPopup, setShowPopup] = useState(false)
  const [popupMessage, setPopupMessage] = useState("")
  const [redeemHistory, setRedeemHistory] = useState([])
  const [pagination, setPagination] = useState({
    pageNumber: 1,
    pageSize: constants.contentPageSize,
    totalPages: 1,
    first: true,
    last: false,
  })
  const [pageNumber, setPageNumber] = useState(1)
  // const [keyword, setKeyword] = useState("")
  // const [order, setOrder] = useState("desc")

  const handleRedeemSuccess = (seriesName) => {
    setPopupMessage(`您已經成功兌換：${seriesName}。`)
    setShowPopup(true)
    setButtonRedeemPopup(false)
  }
  const handleSelectPage = (pageNumber) => {
    setPageNumber(pageNumber)
  }
  useEffect(() => {
    const fetchData = async () => {
      const pageData = await apiService.apiRedeemHistory({
        // keyword: keyword,
        // order: order,
        page: pageNumber,
      })
      setRedeemHistory(pageData.content)
      const totalPages = Math.ceil(pageData.totalItems / pageData.pageSize)
      setPagination({
        pageNumber: pageData.pageNumber,
        pageSize: pageData.pageSize,
        totalPages: totalPages,
        first: pageData.pageNumber === 1,
        last: pageData.pageNumber === totalPages,
      })
    }
    fetchData()
    // }, [pageNumber, showPopup, keyword, order])
  }, [pageNumber, showPopup])

  return (
    <div className="container">
      <div className="d-flex align-items-center justify-content-between title_content position-relative title-member">
        <Title data={PageTitle} />
        <div className="d-md-block d-none btn-exchange">
          <MemberExchange handleClick={setButtonRedeemPopup} />
        </div>
      </div>
      <div className="member_page page">
        <div className="row">
          <div className="col-xl-3 col-lg-4 mb-4">
            <MemberNav active={NavActiveIndex} />
          </div>
          <div className="col-xl-9 col-lg-8">
            <div className="d-md-none d-block btn-exchange mb-3">
              <MemberExchange handleClick={setButtonRedeemPopup} />
            </div>
            <div className="member_content">
              <div className="row">
                {redeemHistory.map((giftCard) => (
                  <div className="col-lg-6" key={giftCard.id}>
                    <ListItemRecord giftCard={giftCard} />
                  </div>
                ))}
              </div>
              {!redeemHistory || redeemHistory.length === 0 &&
                <div className="member_empty">
                  <img src={IconEmpty} alt="" />
                  <p>這裡空空的...</p>
                </div>
              }
            </div>
            <Pagination pagination={pagination} onSelectPage={handleSelectPage} />
          </div>
        </div>
      </div>
      <PopupLoginTemplate
        trigger={buttonRedeemPopup}
        setTrigger={setButtonRedeemPopup}
      >
        <Exchange onRedeemSuccess={handleRedeemSuccess} />
      </PopupLoginTemplate>
      <PopupSuccess
        trigger={showPopup}
        setTrigger={setShowPopup}
        title="兌換完成"
        message={popupMessage}
        okLabel="確定"
      />
    </div>
  )
}

export default MemberRecord
