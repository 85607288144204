import React from 'react';

import ArticleContentTitle from './article-content_title';
import ArticleContentInner from './article-content_inner';

function ArticleContent({product}){
    return (
        <div className='article_inner'>
            <ArticleContentTitle product={product} />
            <ArticleContentInner product={product} />
        </div>
    );
}

export default ArticleContent;