import React from 'react';

import IconTransfer from '../../img/icon_transfer.svg'


function MemberExchange({handleClick}){
    return (
        <button className='btn-icontext' onClick={() => handleClick(true)}>
            <img src={IconTransfer} alt="" />
            <span>我要兌換</span>
        </button>
    );
}

export default MemberExchange;