import React, { useState } from "react"
import RTUtils from "../../lib/rt-utils"
import PopupHint from "./popup-hint-template-1"
import apiService from "../../service/apiService"

function ForgetPassword2({ token, onOk }) {
  const [errMsg, setErrorMessage] = useState()
  const [password, setPassword] = useState("")
  const [confirmPassword, setConfirmPassword] = useState("")
  const [showPopup, setShowPopup] = useState(false)

  const showErrorMessage = (msg) => {
    setErrorMessage(msg)
    setTimeout(() => {
      setErrorMessage("")
    }, 5000)
  }

  const validateData = () => {
    if (!RTUtils.validatePassword(password)) {
      showErrorMessage("密碼為6-20字元英數字")
      return false
    }
    if (password !== confirmPassword) {
      showErrorMessage("密碼與確認密碼必須相符")
      return false
    }
    return true
  }

  const handleClick = async (e) => {
    e.preventDefault()
    if (!validateData()) return
    try {
      const resp = await apiService.apiResetPassword({
        token: token,
        newPassword: password
      })
      console.log(resp)
      setShowPopup(true)
    } catch (err) {
      console.log(err)
      const errMsg = err.data.error.message ?? '重設密碼連結已失效，請重新申請。'
      showErrorMessage(errMsg)
    }
  }

  return (
    <div className="popup-medium_content">
      <PopupHint
        trigger={showPopup}
        setTrigger={setShowPopup}
        title="變更成功"
        message="您已完成密碼變更，請使用新密碼登入。"
        onOk={() => onOk()}
      />
      <h5 className="popup_title login">重設密碼</h5>
      <p className="popup_intro">
        此密碼為往後登入的密碼，請填寫6-20位英文數字
      </p>
      <form action="" className="popup_form">
        <input
          className="input-common"
          type="password"
          placeholder="新密碼"
          value={password}
          onChange={({ target }) => setPassword(target.value)}
        />
        <input
          className="input-common"
          type="password"
          placeholder="確認密碼"
          value={confirmPassword}
          onChange={({ target }) => setConfirmPassword(target.value)}
        />
        <button className="btn-common" onClick={handleClick}>
          確定送出
        </button>
        {errMsg && <p className="popup_hint">{errMsg}</p>}
      </form>
    </div>
  )
}

export default ForgetPassword2
