import { React } from 'react';
import { Link, useLocation } from 'react-router-dom';
import useUserStatus from '../../hooks/useUserStatus';

import iconWatchGray from '../../img/icon_watch-gray.svg';
import iconWatchBlack from '../../img/icon_watch-black.svg';
import iconListenGray from '../../img/icon_listen-black.svg';
import iconListenBlack from '../../img/icon_listen-gray.svg';
import iconReadGray from '../../img/icon_read-gray.svg';
import iconReadBlack from '../../img/icon_read-black.svg';
import iconCartGray from '../../img/icon_cart-gray.svg';
import iconCartBlack from '../../img/icon_cart-black.svg';
import iconUserGray from '../../img/icon_user-gray-m.svg';
import iconUserBlack from '../../img/icon_user-black.svg';
import iconHomeGray from '../../img/icon_home-gray.svg';
import iconHomeBlack from '../../img/icon_home-black.svg';
import iconSigninGray from '../../img/icon_signin-gray.svg';
import iconSigninBlack from '../../img/icon_signin-black.svg';
import constants from '../../lib/constants';
import iconWatchColor from '../../img/icon_watch_color.svg';
import iconListenColor from '../../img/icon_listen_color.svg';
import iconReadColor from '../../img/icon_read_color.svg';
import iconLesson from '../../img/icon_lesson.svg';

function HeaderNavM({ userInitial, onLogin, onSignup, onLogout }) {
    const isLoggedIn = useUserStatus({ caller: 'mobile' });
    const path = useLocation().pathname;
    const navClass = isLoggedIn ? 'header_nav_user login' : 'header_nav_user';

    const MenuItemsLoggedIn = () => {
        return (
            <>
                <ul className="header_nav-m_list">
                    <li>
                        <Link className={path.includes('watch') ? 'active' : ''} to="/watch">
                            <LinkWatch />
                        </Link>
                        <Link className={path.includes('listen') ? 'active' : ''} to="/listen">
                            <LinkHear />
                        </Link>
                        <Link className={path.includes('read') ? 'active' : ''} to="/read">
                            <LinkRead />
                        </Link>
                        {/* <a href={constants.cartUrl} target='_blank' rel="noreferrer"><LinkCart /></a> */}
                        <Link to="/">
                            <LinkLessonLogin />
                        </Link>
                        <Link className={path.includes('member') ? 'active' : ''} to="/member-favorite">
                            <LinkUser />
                        </Link>
                        <LinkTellypenInstructions />
                        <Link to="/">
                            <LinkHome />
                        </Link>
                    </li>
                </ul>
                <a
                    href="/"
                    className="btn-common btn-logout"
                    onClick={e => {
                        // console.log('clicked M logout')
                        e.preventDefault();
                        onLogout();
                    }}>
                    登出
                </a>
            </>
        );
    };
    const MenuItems = () => {
        return (
            <ul className="header_nav-m_list">
                <li>
                    <Link
                        to=""
                        onClick={e => {
                            e.preventDefault();
                            onLogin();
                        }}>
                        <LinkLogin />
                    </Link>
                    <Link
                        to=""
                        onClick={e => {
                            e.preventDefault();
                            onSignup();
                        }}>
                        <LinkSignup />
                    </Link>
                    <Link to="/">
                        <LinkLesson />
                    </Link>
                    <LinkTellypenInstructions />
                </li>
            </ul>
        );
    };
    return (
        <nav className="header_nav-m d-md-none d-block">
            <div className={navClass}>
                <span>{userInitial}</span>
            </div>
            {isLoggedIn ? <MenuItemsLoggedIn /> : <MenuItems />}
        </nav>
    );
}

export default HeaderNavM;

const LinkLogin = () => (
    <div className="d-flex align-items-center header_nav-m_item">
        <img className="header_nav-m_icon user normal" src={iconUserGray} alt="" />
        <img className="header_nav-m_icon user active" src={iconUserBlack} alt="" />
        <span>登入</span>
    </div>
);

const LinkSignup = () => (
    <div className="d-flex align-items-center header_nav-m_item">
        <img className="header_nav-m_icon normal" src={iconSigninGray} alt="" />
        <img className="header_nav-m_icon active" src={iconSigninBlack} alt="" />
        <span>註冊</span>
    </div>
);

const LinkWatch = () => (
    <div className="d-flex align-items-center header_nav-m_item">
        <img className="header_nav-m_icon" src={iconWatchColor} alt="" />
        <span>看</span>
    </div>
);

const LinkHear = () => (
    <div className="d-flex align-items-center header_nav-m_item">
        <img className="header_nav-m_icon" src={iconListenColor} alt="" />
        <span>聽</span>
    </div>
);

const LinkRead = () => (
    <div className="d-flex align-items-center header_nav-m_item">
        <img className="header_nav-m_icon" src={iconReadColor} alt="" />
        <span>讀</span>
    </div>
);

const LinkCart = () => (
    <div className="d-flex align-items-center header_nav-m_item">
        <img className="header_nav-m_icon normal" src={iconCartGray} alt="" />
        <img className="header_nav-m_icon active" src={iconCartBlack} alt="" />
        <span>買書</span>
    </div>
);

const LinkUser = () => (
    <div className="d-flex align-items-center header_nav-m_item">
        <img className="header_nav-m_icon user normal" src={iconUserGray} alt="" />
        <img className="header_nav-m_icon user active" src={iconUserBlack} alt="" />
        <span>會員專區</span>
    </div>
);

const LinkHome = () => (
    <div className="d-flex align-items-center header_nav-m_item">
        <img className="header_nav-m_icon normal" src={iconHomeGray} alt="" />
        <img className="header_nav-m_icon active" src={iconHomeBlack} alt="" />
        <span>回首頁</span>
    </div>
);

const LinkLessonLogin = () => (
    <div className="d-flex align-items-center header_nav-m_item">
        <img className="header_nav-m_icon" src={iconLesson} alt="" />
        <span>我的課程</span>
    </div>
);

const LinkLesson = () => (
    <div className="d-flex align-items-center header_nav-m_item">
        <img className="header_nav-m_icon" src={iconLesson} alt="" />
        <span>課程兌換</span>
    </div>
);

const LinkTellypenInstructions = () => (
    <div className="d-flex align-items-center header_nav-m_item">
        <img className="header_nav-m_icon" src={iconReadBlack} alt="" />
        <Link to="tellypen-instructions">
            <span>
                <strong>點讀音檔下載說明</strong>
            </span>
        </Link>
    </div>
);
