import React from 'react';

import IconFB from '../../img/icon_fb_bgWhite.svg';
import IconIG from '../../img/icon_ig_bgWhite.svg';
import IconYT from '../../img/icon_yt_bgWhite.svg';
import IconLine from '../../img/icon_line_bgWhite.svg';
import constants from '../../lib/constants';

function FooterSocial(){
    return (
        <div className="d-flex footer_social order-lg-2 order-1">
            <a href={constants.facebookUrl}><img src={IconFB} alt="" /></a>
            <a href={constants.instagramUrl}><img src={IconIG} alt="" /></a>
            <a href={constants.youtubeUrl}><img src={IconYT} alt="" /></a>
            <a href={constants.lineUrl}><img src={IconLine} alt="" /></a>
        </div>
    );
}

export default FooterSocial;