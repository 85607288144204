import React, { useEffect, useState } from 'react';

import Title from '../component/common/title';
import CardCart from '../component/list/card_cart';
import Order from '../component/content/order';

import iconHead from '../img/icon_head.svg';
import apiService from '../service/apiService';
import PubSub from "pubsub-js"
import IconEmpty from '../img/icon_empty.svg';
import { Default } from 'react-awesome-spinners';
import { useNavigate } from 'react-router';
import SweetAlert2 from 'react-sweetalert2';

const PageTitle = { pic: iconHead, name: "購物車", parent: "會員專區" }
function Cart() {
    const navigate = useNavigate();
    const [cartData, setCartData] = useState([]);
    const [isLoading, setIsLoading] = useState(false);
    const [swalProps, setSwalProps] = useState({});

    const fetchData = async () => {
        try {
            setIsLoading(true);
            const result = await apiService.getCarts();
            setCartData(result);
        } catch (error) {
            console.log(error);
        } finally {
            setIsLoading(false);
        }
    }

    const onRemove = async (cartId) => {
        try {
            await apiService.removeFromCart({ id: cartId });
            const result = await apiService.getCarts();
            setCartData(result);
            PubSub.publish("UPDATE_CART_COUNT")
        } catch (error) {
            console.log(error);
        }
    }

    const handlePreCheckout = async () => {
        try {
            setIsLoading(true);
            const res = await apiService.preCheckout();
            console.log('res:', res)
            navigate('/order');
        } catch (error) {
            console.log(error)
            setSwalProps({
                show: true,
                title: '',
                text: error.data.error.message,
                icon: 'warning',
                confirmButtonColor: '#FCC800',
                onConfirm: () => setSwalProps({ show: false })
            });
        } finally {
            setIsLoading(false);
        }
    }


    useEffect(() => {
        fetchData();
    }, []);

    if (isLoading) {
        return (
            <div className="loader">
                <Default color="black" />
                <p>讀取中</p>
            </div>
        )
    }

    return (
        <div className='container'>
            <div className="title_content title-member">
                <Title data={PageTitle} />
            </div>
            <div className="member_page cart">
                <div className="row">
                    <div className="col-xl-8 col-lg-7">
                        <div className="member_content">
                            {
                                cartData.map((data, index) => (
                                    <CardCart cartData={data} key={index} handleRemove={onRemove} />
                                ))
                            }
                            {cartData.length === 0 && <div className="empty_cart">
                                <div className="member_empty">
                                    <img src={IconEmpty} alt="" />
                                    <p>購物車內還沒有商品喔！趕快來選購吧！</p>
                                </div>
                            </div>}
                        </div>
                    </div>
                    {cartData.length > 0 &&
                        <div className="col-xl-4 col-lg-5">
                            <div className="cart_order">
                                <Order cartData={cartData} preCheckout={handlePreCheckout} />
                            </div>
                        </div>}
                </div>
            </div>
            <SweetAlert2 {...swalProps} />
        </div>
    );
}

export default Cart;