import { React, useEffect, useState } from 'react';

import Title from '../component/common/title';
import OrderBuyer from '../component/member/order-buyer';
import OrderInvoice from '../component/member/order-invoice';
import OrderReceiver from '../component/member/order-receiver';
import Check from '../component/common/check';
import OrderInform from '../component/member/order-inform';
import OrderInformCheck from '../component/member/order-inform-check';

import iconHead from '../img/icon_head.svg';
import apiService from '../service/apiService';
import { Default } from 'react-awesome-spinners';
import SweetAlert2 from 'react-sweetalert2';
import constants from '../lib/constants';

const PageTitle = { pic: iconHead, name: "訂單資訊頁", parent: "會員專區" }
const agreement = "我同意當提交退貨申請後，即代表同意格林文化的退貨原則，並接受格林文化代為處理發票作廢及相關的後續處理事宜。"

function Order() {
    const [checkoutInfo, setCheckoutInfo] = useState(undefined)
    const [isLoading, setIsLoading] = useState(false)
    const [agreed, setAgreed] = useState(false)
    const [swalProps, setSwalProps] = useState({});

    const showAlert = (title, text) => {
        setSwalProps({
            show: true,
            title: title,
            text: text,
            icon: 'warning',
            confirmButtonColor: '#FCC800',
            iconColor: '#FCC800',
            onConfirm: () => setSwalProps({ show: false })
        });
    }

    useEffect(() => {
        const loadCheckoutInfo = async () => {
            try {
                setIsLoading(true)
                const response = await apiService.getCheckoutInfo()
                setCheckoutInfo(response)
            } catch (error) {
                console.log(error)
            } finally {
                setIsLoading(false)
            }
        }
        loadCheckoutInfo()
    }, [])

    const onChangeBasicInfo = (e) => {
        setCheckoutInfo({ ...checkoutInfo, basicInfo: { ...checkoutInfo.basicInfo, [e.target.name]: e.target.value } })
    }

    const onUsePromoCode = async (promoCode) => {
        try {
            let resp;
            if (promoCode === '') {
                resp = await apiService.unusePromoCode()
            } else {
                resp = await apiService.usePromoCode({ promoCode: promoCode })
            }
            setCheckoutInfo(resp)
        } catch (error) {
            // console.log(error)
            showAlert('折扣代碼沒有生效', error.data.error.message)
        }
    }

    const onSameBuyerChange = (ischecked) => {
        if (ischecked) {
            setCheckoutInfo({
                ...checkoutInfo, basicInfo: {
                    ...checkoutInfo.basicInfo,
                    invoiceName: checkoutInfo.basicInfo.buyerName,
                    invoicePhone: checkoutInfo.basicInfo.buyerPhone,
                }
            })
        } else {
            setCheckoutInfo({
                ...checkoutInfo, basicInfo: {
                    ...checkoutInfo.basicInfo,
                    invoiceName: '',
                    invoicePhone: '',
                }
            })
        }
    }

    const onPay = async () => {
        // check if all required fields are filled
        if (!agreed) {
            showAlert('退貨條款', '請勾選同意退貨條款')
            return
        }
        if (!checkoutInfo.basicInfo.buyerName) {
            showAlert('訂購人資訊', '請填寫訂購人姓名')
            return
        }
        if (!checkoutInfo.basicInfo.buyerPhone) {
            showAlert('訂購人資訊', '請填寫訂購人電話')
            return
        }
        if (checkoutInfo.basicInfo.invoiceType === 1 && checkoutInfo.basicInfo.invoiceCarrier > 1) {
            if (!checkoutInfo.basicInfo.invoiceInfo) {
                showAlert('發票資訊', '請填寫發票載具')
                return
            } 
            // ※手機條碼載具檢核：第 1 碼為 / +7 碼英、數字，除第 1 碼外，只有 0123456789ABCDEFGHIJKLMNOPQRSTUVWXYZ + - . 這 39 個字元，限大寫英字。                                           
            if (checkoutInfo.basicInfo.invoiceCarrier == 2 && !/^\/[0-9A-Z+\-.]{7}$/.test(checkoutInfo.basicInfo.invoiceInfo)) {
                showAlert('發票資訊', '發票載具格式錯誤')
                return
            } 
            // ※自然人憑證條碼檢核：２碼大寫英字+14碼數字。
            else if (checkoutInfo.basicInfo.invoiceCarrier == 3 && !/^[A-Z]{2}\d{14}$/.test(checkoutInfo.basicInfo.invoiceInfo)) {
                showAlert('發票資訊', '發票載具格式錯誤')
                return
            }
        }
        if (checkoutInfo.basicInfo.invoiceType === 2 && !checkoutInfo.basicInfo.invoiceInfo) {
            showAlert('發票資訊', '請填寫公司戶電子發票 / 統一編號')
            return
        }
        if (!checkoutInfo.basicInfo.invoiceName) {
            showAlert('收件人', '請填寫姓名')
            return
        }
        if (!checkoutInfo.basicInfo.invoicePhone) {
            showAlert('收件人', '請填寫電話')
            return
        }
        if (!checkoutInfo.basicInfo.invoiceEmail) {
            showAlert('收件人', '請填寫收件人電子信箱')
            return
        }
        if (!checkoutInfo.basicInfo.invoiceAddrCity) {
            showAlert('收件人', '請選擇縣市')
            return
        }
        if (!checkoutInfo.basicInfo.invoiceAddrDistrict) {
            showAlert('收件人', '請選擇鄉鎮市區')
            return
        }
        if (!checkoutInfo.basicInfo.invoiceAddrLine) {
            showAlert('收件人', '請填寫巷弄號樓')
            return
        }
        try {
            const resp = await apiService.pay(checkoutInfo.basicInfo)
            console.log(resp)
            formPostToNewebpay(resp)
        } catch (error) {
            console.log(error)
        }
    }

    const formPostToNewebpay = async (data) => {
        /* data:
        {
            "merchantID": "MS350970818",
            "tradeInfo": "d97364ed5465e7430f0f0474e32665646e266bef4fa6c5a84982a01dd4614e600c353368f489037c1def4e82e1fa3f84a5c460cad4bea30e95e3274c3469accfd213f3021cbb9d317ae07d33d7a3bbc7a163e5990a40935d9009f345abf7a96d99f0d30e0d49d0fdd17cec32e9a49c3475ec20283196df6d3ca52d8176254fc8a089110a0f896bf781f46dff508cc9c950b59491b75d5acdd23ac3b49a2a9385ab4e161d0922ad0133c9ea3763052afa56b2c6bad1133402d94b0bcf7f31375b8c319ce940d2297a1e89ff086e6405d59d8303308821cc88114ca073875672c5ba0a04db5d215785c1b8949bc4f87da4f4f51be26edd2e69ce0b729383a902312a085c1018b0c00b09430dbd0bbee05766beb80ee0f1be6e1e1c1e4e40a253bb346c0371672d3bda21732a38940bcf5c19f267b63c8dd367cd51e78f873dd9d97eeebb40bc3a6ea446c83a427294088acb58f104d62651557736359a54ddf8ad9d05142434bdc58c4219d431bee6f12c",
            "tradeSha": "8426184365be5deb0f484ad3832939d9851d802f4df76f91260035df224b7783",
            "version": "2.0"
        }
        */
        const form = document.createElement('form');
        form.method = 'post';
        form.action = constants.paymentUrl;
        for (const key in data) {
            const input = document.createElement('input');
            input.type = 'hidden';
            input.name = key;
            input.value = data[key];
            form.appendChild(input);
        }
        document.body.appendChild(form);
        form.submit();
    }

    return (
        <div className='container'>
            {isLoading && <div className="loader"><Default color="black" /><p>讀取中</p></div>}
            {!isLoading && (<>
                <div className="title_content title-member">
                    <Title data={PageTitle} />
                </div>
                <div className="member_page page-order">
                    <div className="row">
                        <div className="col-xl-8 col-lg-7 order-lg-1 order-2">
                            <div className="member_content">
                                <OrderBuyer checkoutInfo={checkoutInfo} onChange={onChangeBasicInfo} />
                                <OrderInvoice checkoutInfo={checkoutInfo} onChange={onChangeBasicInfo} />
                                <OrderReceiver checkoutInfo={checkoutInfo} onChange={onChangeBasicInfo} onSameBuyerChange={onSameBuyerChange} />
                            </div>
                            <div className="order_agree"><Check data={agreement} checked={agreed} onChange={(ischecked) => setAgreed(ischecked)} /></div>
                        </div>
                        <div className="col-xl-4 col-lg-5 order-lg-2 order-1">
                            <div className="cart_order">
                                <OrderInform checkoutInfo={checkoutInfo} onUsePromoCode={onUsePromoCode} onPay={onPay} />
                            </div>
                        </div>
                        <div className="col-12 d-lg-none order-3">
                            <OrderInformCheck checkoutInfo={checkoutInfo} onPay={onPay} />
                        </div>
                    </div>
                </div>
            </>)}
            <SweetAlert2 {...swalProps} />
        </div>
    )
}

export default Order;