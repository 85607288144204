import req from "../lib/request"

const apiService = {
  // user
  apiLogin: (params) => req.post('auth/login', params),
  apiLogout: () => req.post('auth/logout'),
  apiSignup: (params) => req.post('user', params),
  apiUpdateUser: (params) => req.patch(`user`, params),
  apiGetUserInfo: () => req.get('user'),
  apiForgetPassword: (params) => req.post('user/forget', params),
  apiResetPassword: (params) => req.post('user/reset-password', params),
  apiGetCartCount: () => req.get('user/cart/count'),
  // location
  listCities: () => req.get('location/cities'),
  listDistricts: (city) => req.get('location/districts', { city }),
  // home
  apiHomeBanners: (params) => req.get('/home/banners', params),
  apiTop3: () => req.get('home/top3'),
  // product pages /page?type=0...3
  apiProducts: (params) => req.get('products', params),
  apiPage: (params) => req.get('products/page', params),
  apiSeries: (params) => req.get('products/series', params),
  apiProduct: (params) => req.get(`products/${params.id}`),
  apiPrevNext: (params) => req.get(`products/${params.id}/prev-next`),
  // lessons
  getLessonsPage: () => req.get('lessons/page'),
  searchLessons: (params) => req.get('lessons/search', params),
  getLessonDetails: (params) => req.get(`lessons/${params.id}`),
  getLessonSalesPlans: (params) => req.get(`lessons/${params.id}/sales-plans`),
  getLessonChapters: (params) => req.get(`lessons/${params.id}/chapters`),
  getLessonQa: (params) => req.get(`lessons/${params.id}/qa`),
  getLessonAnnouncement: (params) => req.get(`lessons/${params.id}/announcement`),
  getLessonComment: (params) => req.get(`lessons/${params.id}/comment`),
  updateLessonComment: (params) => req.patch(`lessons/${params.id}/comment/${params.commentId}`, params),
  postLessonComment: (params) => req.post(`lessons/${params.id}/comment`, params),
  deleteLessonComment: (params) => req.delete(`lessons/${params.id}/comment/${params.commentId}`),
  getClassroom: (params) => req.get(`lessons/${params.id}/classroom`),
  // User history
  apiGetHistory: (params) => req.get('user/read-history', params),
  apiPostHistory: (params) => req.post('user/read-history', params),
  // User like
  apiPostLikes: (params) => req.post('user/like', params),
  apiGetLikes: (params) => req.get('user/like', params),
  // redeem gift card
  apiRedeem: (params) => req.post('products/redeem', params),
  apiRedeemHistory: (params) => req.get('products/redeem-history', params),
  // member center
  addToMyCollections: (params) => req.post('member/collections', params),
  getMyCollections: (params) => req.get('member/collections', params),
  deleteFromMyCollectionsById: (params) => req.delete(`member/collections/${params.id}`),
  deleteFromMyCollections: (params) => req.post('member/collections/remove', params),
  getMyLessons: (params) => req.get('member/mylessons', params),
  // cart
  addToCart: (params) => req.post('member/carts', params),
  getCarts: (params) => req.get('member/carts', params),
  removeFromCart: (params) => req.delete(`member/carts/${params.id}`),
  getOrders: (params) => req.get('member/orders', params),
  // checkout and promo code
  preCheckout: () => req.post('member/pre-checkout'),
  getCheckoutInfo: (params) => req.get('member/checkout', params),
  checkout: (params) => req.post('member/checkout', params),
  usePromoCode: (params) => req.post('member/use-promo-code', params),
  unusePromoCode: () => req.post('member/unuse-promo-code'),
  // payment
  pay: (params) => req.post('payment/pay', params),
  // coupon
  getCoupons: (params) => req.get('member/coupons', params),
  useCoupon: (params) => req.post(`member/coupons/${params.id}/use`, params),
  unuseCoupon: (params) => req.post(`member/coupons/${params.id}/unuse`, params),
  // faq
  getFaq: () => req.get('faq'),
  // user exists?
  isUserExists: (params) => req.get('user/exists', params),
  // line
  lineConnect: (params) => req.post('line/connect', params),
  lineLogin: (params) => req.post('line/login', params),
}

export default apiService
