import React from 'react';

import HomeBannerStatic from '../component/home/home_banner_static';
import LessonList from '../component/list/lessonList';
import HomeOtherList from '../component/home/home_otherList';
import HeaderNewLogin from '../container/Header-new-login'

import Banner from '../img/banner-pic.png';
import BannerM from '../img/pic-3.png';
import IconLesson from '../img/icon_lesson.svg';
import IconWatch from '../img/icon_watch_color.svg';
import IconListen from '../img/icon_listen_color.svg';
import IconRead from '../img/icon_read_color.svg';
import LessonPic1 from '../img/pic9.png';
import LessonPic2 from '../img/pic10.png';

const HomeBannerPic = [
    {
        pc:Banner,
        mobile:BannerM,
    },
    {
        pc:Banner,
        mobile:BannerM,
    },
    {
        pc:Banner,
        mobile:BannerM,
    },
];

const TitleLesson = {
    pic:IconLesson,
    title:"最新課程",
}

const TitleWatch = {
    pic:IconWatch,
    title:"看",
}

const TitleLiten = {
    pic:IconListen,
    title:"聽",
}

const TitleRead = {
    pic:IconRead,
    title:"讀",
}

const lesson = [
    {
        discount: true,
        pic:LessonPic1,
        title:"標題文字標題文字標題文字標題文字標題文標題文字標題文字文字標題",
        teacher:"講師名",
        time:"剩餘時間 17時49分",
        price:"7,200",
        origin:"NT$ 7,200",
    },
    {
        discount: true,
        pic:LessonPic2,
        title:"標題文字標題文字標題文字標題文字標題文標題文字標題文字文字標題標題文字標題文字標題文字標題文字標題文標題文字標題文字文字標題",
        teacher:"講師名2",
        time:"剩餘時間 18時49分",
        price:"8,200",
        origin:"NT$ 7,200",
    },
    {
        discount: false     ,
        pic:LessonPic1,
        title:"題文字文字標題",
        teacher:"講師名3",
        time:"",
        price:"10,200",
        origin:"",
    }
]

function HomeNew(){
    return (
        <div>
            <HeaderNewLogin />
            <HomeBannerStatic banners={HomeBannerPic} />
            <div className='container'>
                <div className="home_item">
                    <LessonList title={TitleLesson} lesson={lesson}/>
                </div>
                <div className="home_item">
                    <HomeOtherList title={TitleWatch}/>
                </div>
                <div className="home_item">
                    <HomeOtherList title={TitleLiten}/>
                </div>
                <div className="home_item">
                    <HomeOtherList title={TitleRead}/>
                </div>
            </div>
        </div>
    );
}

export default HomeNew;