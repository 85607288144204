import React from 'react';

import LessonTitle from './lesson-title';
import LessonChapter from './lesson-chapter';
import { Button } from 'antd';
import tellypenDownloadBtn from '../../img/tellypen-download-btn.png';

function ClassroomContent({ title, chapter, mylesson, tellypenFileUrl }) {
    return (
        <div className="classroom_content">
            <div className="classroom-content-header">
                <h1 className="classroom_content_title">{title}</h1>
                {tellypenFileUrl && (
                    <Button className="tellypen-download-btn" onClick={() => window.open(tellypenFileUrl, '_blank')}>
                        <img src={tellypenDownloadBtn} alt="tellypen download" />
                    </Button>
                )}
            </div>
            <LessonTitle title={'課程章節'} />
            <LessonChapter chapter={chapter} mylesson={mylesson} />
        </div>
    );
}

export default ClassroomContent;
