import React from "react"
import FavoriteBtn from "../../component/common/favorite-btn"

function ContentText({ product }) {
  return (
    <div className="container article_content">
      <div className="d-flex align-items-center justify-content-between mb-5">
        <h2 className="title_article m-0">{product.name}</h2>
        <div className="d-md-flex justify-content-end d-none">
          <FavoriteBtn product={product} />
        </div>
      </div>
      {/* <p>{product.description}</p> */}
      <div dangerouslySetInnerHTML={{ __html: product.description }}></div>
    </div>
  )
}

export default ContentText
