import React from 'react';
import constants from '../../lib/constants'
import IconArrowWhite from '../../img/icon_arrow-white.svg';

// 依類別篩選
function FilterType({onChange}){
  const list = [
    { label: '全部', type: -1},
    { label: '看', type: constants.productType.watch },
    { label: '聽', type: constants.productType.listen },
    { label: '讀', type: constants.productType.read }
  ]
    return (
        <div>
            <label className='input_label' htmlFor="">篩選</label>
            <div className="input">
                <div className="input_content"></div>
                <div className="input_btn"><img src={IconArrowWhite} alt="" /></div>
                <select className='input_select' defaultValue={""} onChange={(e) => onChange(e.target.value)} >
                    <option value="" disabled>請選擇類別</option>
                    {list.map(m => <option key={m.label} value={m.type}>{`${m.label}`}</option>)}
                </select>
            </div>
        </div>
    );
}

export default FilterType;