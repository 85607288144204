import React from 'react';

import LessonQa from './lesson-qa';

function QaContent({ qaItem }) {
    return (
        qaItem.map((data, index) => (
            <div className="qa_content_item" key={index}>
                <h2>{data.category}</h2>
                <LessonQa qa={data.qaList} />
            </div>
        ))
    );
}

export default QaContent;