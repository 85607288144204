import React from 'react';
import ReactDOM from 'react-dom';
import App from './container/App';

import './style/bootstrap-grid.css';
import './style/style.scss';

ReactDOM.render(
  <App />,
  document.getElementById("root")
)

console.log(`baseurl = ${process.env.REACT_APP_API_BASE_URL}`);
