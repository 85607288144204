/****************************************************************************************************************
 * 
 * 課程列表頁
 * 
 ****************************************************************************************************************/
import React, { useState, useEffect } from 'react';

import ListBanner from "../component/list/list-banner"
import LessonList from "../component/list/lessonList";

import IconLesson from '../img/icon_lesson.svg';
import LessonPic1 from '../img/pic9.png';
import LessonPic2 from '../img/pic10.png';

import apiService from "../service/apiService"
import constants from "../lib/constants"
import { Default } from "react-awesome-spinners"


const TitleLesson = [
    {
        pic: IconLesson,
        title: "最新課程",
        link: "/latest-lesson"
    },
    {
        pic: IconLesson,
        title: "熱門課程",
    },
    {
        pic: IconLesson,
        title: "限時優惠",
    }
]

const lesson = [
    {
        discount: true,
        pic: LessonPic1,
        title: "標題文字標題文字標題文字標題文字標題文標題文字標題文字文字標題",
        teacher: "講師名",
        time: "剩餘時間 17時49分",
        price: "7,200",
        origin: "NT$ 7,200",
    },
    {
        discount: true,
        pic: LessonPic2,
        title: "標題文字標題文字標題文字標題文字標題文標題文字標題文字文字標題標題文字標題文字標題文字標題文字標題文標題文字標題文字文字標題",
        teacher: "講師名2",
        time: "剩餘時間 18時49分",
        price: "8,200",
        origin: "NT$ 7,200",
    },
    {
        discount: false,
        pic: LessonPic1,
        title: "題文字文字標題",
        teacher: "講師名3",
        time: "",
        price: "10,200",
        origin: "",
    }
]


function Lesson() {
    const [banners, setBanners] = useState([])
    // 最新課程
    const [latest, setLatest] = useState([])
    // 熱門課程
    const [hot, setHot] = useState([])
    // 限時優惠
    const [discount, setDiscount] = useState([])

    const [like, setLike] = useState([])
    const [loading, setLoading] = useState(false)

    useEffect(() => {
        const fetchData = async () => {
            setLoading(true)
            try {
                const banners = await apiService.apiHomeBanners({ location: "LESSONS" })
                setBanners(banners)
                const page = await apiService.getLessonsPage()
                setLatest(page.latest)
                setHot(page.hot)
                setDiscount(page.discount)
                setLike(page.like)
            } catch (e) {
            } finally {
                setLoading(false)
            }
        }
        fetchData()
    }, [])

    return (
        <div>
            {loading && <div className="loader"><Default color="black" /><p>讀取中</p></div>}
            {!loading && (<>
                <ListBanner banners={banners} />
                <div className='container'>
                    <div className="lesson_listItem">
                        <LessonList title={TitleLesson[0]} contentList={latest} />
                    </div>
                    <div className="lesson_listItem">
                        <LessonList title={TitleLesson[1]} contentList={hot} />
                    </div>
                    <div className="lesson_listItem">
                        <LessonList title={TitleLesson[2]} contentList={discount} />
                    </div>
                </div>
            </>)}
        </div>
    )
}

export default Lesson;