import {React, useState} from 'react';

import IconMail from '../../img/icon_mail_yellow.svg';

function ServiceInform({inform}){
    return (
        <div className='account_setting'>
            <h2>聯絡資訊</h2>
            <div className="service_inform">
                {
                    inform.map(data => (
                        <div className="service_inform_item">
                            <div className="service_inform_icon"><img src={data.icon} alt="" /></div>
                            <div className="service_inform_text">
                                <h3>{data.title}</h3>
                                <p>{data.content}</p>
                            </div>
                        </div>
                    ))
                }
            </div>
        </div>
    );
}

export default ServiceInform;