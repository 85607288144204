import React from "react"

import FilterSearch from "../../component/common/filter_search"
import FilterMonth from "../../component/common/filter_month"
import FilterArrange from "../../component/common/filter_arrange"
import FilterType from "./filter_type"

function Filter({
  hide = false,
  category,
  keyword,
  order,
  onChangeKeyword,
  onChangeOrder,
  onChangeMonth = undefined,
  onChangeType,
}) {
  return hide ? (
    ""
  ) : (
    <div className="filter">
      {onChangeMonth && (
        <form className="filter_item">
          <FilterMonth onChange={onChangeMonth} />
        </form>
      )}
      <form className="filter_item">
        <FilterSearch keyword={keyword} onChange={onChangeKeyword} />
      </form>
      {onChangeType && (
        <form className="filter_item">
          <FilterType onChange={onChangeType} />
        </form>
      )}
      <form className="filter_item">
        <FilterArrange
          category={category}
          order={order}
          onChange={onChangeOrder}
        />
      </form>
    </div>
  )
}

export default Filter
