import { React, useEffect, useRef, useState } from 'react';

import LessonBanner from '../component/lessonContent/lesson-banner';
import LessonVideo from '../component/lessonContent/lesson-video';
import LessonInform from '../component/lessonContent/lesson-inform';
import LessonReciprocal from '../component/lessonContent/lesson-reciprocal';
import LessonTab from '../component/lessonContent/lesson-tab';
import LessonTeacher from '../component/lessonContent/lesson-teacher';
import FavortiteBtnStatic from '../component/common/favorite-btn-static';
import LessonCase from '../component/lessonContent/lesson-case';

import apiService from '../service/apiService';
import { useParams } from 'react-router';
import { Default } from "react-awesome-spinners"
import useUserStatus from '../hooks/useUserStatus';

const scrollTarget = "lessen_video";

function LessonContent() {
    const { id } = useParams();
    const videoRef = useRef(null);
    const [isPlaying, setIsPlaying] = useState(false);

    const handleClickScroll = () => {
        const element = document.getElementById(scrollTarget);
        if (element) {
            element.scrollIntoView({ behavior: 'smooth' });
            if (videoRef.current) {
                videoRef.current.play();
            }
            setIsPlaying(true);
        }
    };
    const [openState, setOpenState] = useState(0);
    const toggleOpen = (data) => {
        setOpenState(data);
    }
    const [loading, setLoading] = useState(false)
    const [lessonDetails, setLessonDetails] = useState({});
    const [qa, setQa] = useState([])
    const [announcement, setAnnouncement] = useState([])
    const [chapters, setChapters] = useState([])
    const [salesPlans, setSalesPlans] = useState([{}])
    const isLogin = useUserStatus('')

    useEffect(() => {
        const loadData = async (seriesId) => {
            if (!seriesId) return
            setLoading(true)
            try {
                const data = await apiService.getLessonDetails({ id: seriesId })
                setLessonDetails(data)
                const chapters = await apiService.getLessonChapters({ id: seriesId })
                setChapters(chapters)
                const qa = await apiService.getLessonQa({ id: seriesId })
                setQa(qa)
                const announcement = await apiService.getLessonAnnouncement({ id: seriesId })
                setAnnouncement(announcement)
                const salesPlans = await apiService.getLessonSalesPlans({ id: seriesId })
                setSalesPlans(salesPlans)
            } catch (error) {
                console.log(error)
            } finally {
                setLoading(false)
            }
        }
        loadData(id)
    }, [id])

    return (
        <div>
            {loading && <div className="loader"><Default color="black" /><p>讀取中</p></div>}
            {!loading && <>
                <LessonBanner
                    title={lessonDetails.name}
                    description={lessonDetails.description}
                    author={lessonDetails.author}
                    backgroundImage={lessonDetails.videoImage}
                    handleClickScroll={handleClickScroll} />
                <div className="container lesson_content">
                    <div className="row">
                        <div className="col-lg-8">
                            <LessonVideo
                                id={scrollTarget}
                                videoUrl={lessonDetails.videoUrl}
                                ref={videoRef}
                                isPlaying={isPlaying}
                            />
                            <button className='d-lg-none btn-common btn-seecase' onClick={() => toggleOpen(1)}>查看購買方案</button>
                            <LessonInform data={lessonDetails} />
                            {
                                lessonDetails.salesTitle && new Date(lessonDetails.salesEnd) > new Date() &&
                                <LessonReciprocal
                                    title={lessonDetails.salesTitle}
                                    salesEnd={lessonDetails.salesEnd}
                                />
                            }
                            <LessonTab
                                seriesId={id}
                                intro={lessonDetails.introBanners}
                                TabChapter={chapters}
                                TabQa={qa}
                                TabAnnounce={announcement} />
                            <LessonTeacher instructors={lessonDetails.instructors} />
                        </div>
                        <div className="col-lg-4">
                            <LessonCase salesPlans={salesPlans} openState={openState} toggleOpen={toggleOpen} />
                        </div>
                    </div>
                    {isLogin &&
                        <FavortiteBtnStatic seriesId={id} productId={null} defaultAdded={lessonDetails.isLiked} />
                    }
                </div>
            </>}
        </div>
    )
}

export default LessonContent;