import React from 'react';
import { Link } from "react-router-dom";
import HeaderNav from '../component/header/header_nav';
import HeaderIcon from '../component/header/header_icon';
import logo from '../img/logo.svg';

function HeaderListen(){
    return (
        <header className='header'>
            <div className="container d-flex align-items-center justify-content-between">
                <div className="header_left d-flex align-items-center">
                    <Link to="/"><img className='header_logo' src={logo} alt="讀聽格林" /></Link>
                    <HeaderNav />
                </div>
                <div className="header_right">
                    <HeaderIcon />
                </div>
            </div>
        </header>
    );
}

export default HeaderListen;