import { React, useEffect, useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import HomeNew from '../../page/home-new';
import apiService from '../../service/apiService';


function Order({ cartData, preCheckout }) {
    const [subTotal, setSubTotal] = useState(0);
    const [discount, setDiscount] = useState(0);
    const [total, setTotal] = useState(0);

    useEffect(() => {
        let total = 0;
        let subTotal = 0;
        cartData.forEach(item => {
            subTotal += parseInt(item.price.replace(/,/g, ''))
            total += parseInt(item.priceSales.replace(/,/g, ''))
        });
        setSubTotal(subTotal);
        setDiscount(subTotal - total);
        setTotal(total);
    }, [cartData]);

    const addThousandsCommas = (num) => {
        return num.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    }

    return (
        <div className='order'>
            <h5>訂單明細</h5>
            <div className="order_content">
                <div className="order_list">
                    <div className="d-flex align-items-center justify-content-between mb-2">
                        <span>小計</span><span className='origin'>NT$ {addThousandsCommas(subTotal)}</span>
                    </div>
                    <div className="d-flex align-items-center justify-content-between">
                        <span>合購優惠組合</span><span>-NT$ {addThousandsCommas(discount)}</span>
                    </div>
                </div>
                <h6 className="order_price">NT$ {addThousandsCommas(total)}</h6>
                <Link className='order_btn btn-common mb-4' onClick={() => preCheckout()}>前往結帳</Link>
                <Link className='order_btn btn-border' to={"/lesson"}>繼續選購</Link>
            </div>
        </div>
    );
}

export default Order;