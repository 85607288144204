import { React, useEffect, useState } from 'react';
import { Link } from 'react-router-dom';

import HeaderNavM from '../../component/header/header_nav-m';
import PopupLogin from '../popup/popup-login';
import PopupSignin from '../popup/popup-signin';
import PopupLoginTemplate from '../popup/popup-login-template';
import PopupSiginTemplate from '../popup/popup-sigin-template';

import IconCart from '../../img/icon_cart.svg';

import IconMenu from '../../img/icon_menu.svg';
import IconCross from '../../img/icon_cross.svg';

import userService from '../../service/userService';
import apiService from '../../service/apiService';
import useUserStatus from '../../hooks/useUserStatus';

import PubSub from 'pubsub-js';

const handleLogout = async () => {
    try {
        // const resp =
        await apiService.apiLogout();
        // console.log(resp)
    } catch (e) {
        console.error(e);
    }
    await userService.clearUser();
    window.location.reload();
    window.location.href = '/';
};

function HeaderIcon() {
    const isLoggedIn = useUserStatus({ caller: 'pc' });
    const [userNameInitial, setUserNameInitial] = useState('');
    const [burgerMenuActive, setBurgerMenuActive] = useState(false);
    const [buttonPopupLogin, setButtonPopupLogin] = useState(false);
    const [buttonPopupSignup, setButtonPopupSignup] = useState(false);
    const [cartCount, setCartCount] = useState(0);

    useEffect(() => {
        const initial = isLoggedIn && userService.getUser().fullName ? userService.getUser().fullName.charAt(0).toUpperCase() : '';
        setUserNameInitial(initial);

        const loadCartCount = async () => {
            if (isLoggedIn) {
                const resp = await apiService.apiGetCartCount();
                setCartCount(resp.count);
            }
        };
        // if become logged in, get cart count
        loadCartCount();

        PubSub.subscribe('REQUIRE_LOGIN', onLoginRequiredEvent);

        PubSub.subscribe('UPDATE_CART_COUNT', () => {
            loadCartCount();
        });

        return () => {
            PubSub.clearAllSubscriptions();
        };
    }, [isLoggedIn]);

    // login event
    const onLoginRequiredEvent = (msg, data) => {
        console.log(msg, data);
        setButtonPopupLogin(true);
    };

    const MenuItemsLoggedIn = () => {
        return (
            <ul className="header_icon_first d-flex align-items-center">
                <li className="header_navText d-md-block d-none">
                    <Link to="tellypen-instructions">
                        <strong>點讀音檔下載說明</strong>
                    </Link>
                </li>
                {isLoggedIn && (
                    <li className="header_navText d-md-block d-none">
                        <Link to="/member/mylessons">我的課程</Link>
                    </li>
                )}
                <li className="header_cart">
                    <Link to="/cart">
                        <img src={IconCart} alt="Cart Icon" />
                    </Link>
                    {cartCount > 0 && <div className="header_cart_num">{cartCount}</div>}
                </li>
                <li className="d-md-block d-none">
                    <div className="header_icon_login">
                        <span>{userNameInitial}</span>
                    </div>
                    <div className="header_icon_second" style={{ zIndex: 9999 }}>
                        <ul>
                            <li>
                                <Link to="/member-favorite">會員專區</Link>
                            </li>
                            <li onClick={handleLogoutClick}>
                                <a>登出</a>
                            </li>
                        </ul>
                    </div>
                </li>
                <li className={`header_menu d-md-none d-block ${burgerMenuActive ? 'active' : ''}`} onClick={toggleBurgerMenu}>
                    <img className="header_menu_normal" src={IconMenu} alt="" />
                    <img className="header_menu_active" src={IconCross} alt="" />
                    <HeaderNavM userInitial={userNameInitial} onLogin={handleLoginMClick} onSignup={handleSignupMClick} onLogout={handleLogout} />
                </li>
            </ul>
        );
    };

    const MenuItems = () => {
        return (
            <>
                <ul className="header_icon_first d-md-flex d-none align-items-center">
                    <li className="header_navText d-md-block d-none">
                        <Link to="tellypen-instructions">
                            <strong>點讀音檔下載說明</strong>
                        </Link>
                    </li>
                    {isLoggedIn && (
                        <li className="header_navText">
                            <Link to="/member/mylessons">我的課程</Link>
                        </li>
                    )}
                    <li className="header_navText">
                        <a href="/" onClick={handleSignupClick}>
                            註冊
                        </a>
                    </li>
                    <li className="header_navText noline">
                        <a href="/" onClick={handleLoginClick}>
                            登入
                        </a>
                    </li>
                </ul>
                <div className={`header_menu d-md-none d-block ${burgerMenuActive ? 'active' : ''}`} onClick={toggleBurgerMenu}>
                    <img className="header_menu_normal" src={IconMenu} alt="" />
                    <img className="header_menu_active" src={IconCross} alt="" />
                    <HeaderNavM userInitial={userNameInitial} onLogin={handleLoginMClick} onSignup={handleSignupMClick} onLogout={handleLogout} />
                </div>
            </>
        );
    };

    const handleSignupClick = e => {
        e.preventDefault();
        setButtonPopupSignup(true);
    };

    const handleLoginClick = e => {
        e.preventDefault();
        setButtonPopupLogin(true);
    };

    const handleLogoutClick = e => {
        e.preventDefault();
        handleLogout();
    };

    const toggleBurgerMenu = () => {
        setBurgerMenuActive(!burgerMenuActive);
    };

    const handleLoginMClick = () => {
        setBurgerMenuActive(false);
        setButtonPopupLogin(true);
    };

    const handleSignupMClick = () => {
        setBurgerMenuActive(false);
        setButtonPopupSignup(true);
    };

    return (
        <nav className={isLoggedIn ? 'header_icon login' : 'header_icon'}>
            <PopupLoginTemplate trigger={buttonPopupLogin} setTrigger={setButtonPopupLogin}>
                <PopupLogin />
            </PopupLoginTemplate>
            <PopupSiginTemplate trigger={buttonPopupSignup} setTrigger={setButtonPopupSignup}>
                <PopupSignin />
            </PopupSiginTemplate>
            {isLoggedIn ? <MenuItemsLoggedIn /> : <MenuItems />}
        </nav>
    );
}

export default HeaderIcon;
