import React, { useEffect, useState } from "react"

import Check from "../common/check"
import constants from "../../lib/constants"

const topicOptions = constants.topicOptions

function InterestTopic({ user, onChange }) {
  const [topics, setTopics] = useState([])

  useEffect(() => {
    if (user) {
      setTopics(user.topics.split(',').filter((topic) => topicOptions.includes(topic)))
    }
  }, [user])

  const toggleTopic = (topic, isChecked) => {
    let list = topics.filter((i) => i !== topic)
    if (isChecked) {
      list.push(topic)
    }
    setTopics(list)
    user = { ...user, topics: list.join(',') }
    onChange(user)
  }

  return (
    <div className="account_setting interest">
      <h2>興趣主題</h2>
      <div className="d-flex align-items-center flex-wrap">
        {topicOptions.map((topic, index) => (
          <Check
            data={topic}
            checked={topics.includes(topic)}
            onChange={(isChecked) => toggleTopic(topic, isChecked)}
            key={index}
          />
        ))}
      </div>
    </div>
  )
}

export default InterestTopic
