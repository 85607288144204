import React, { useEffect, useState } from 'react';

import IconTag from '../../img/icon_tag.svg';
import IconTagActive from '../../img/icon_tag-active.svg';
import apiService from '../../service/apiService';

function FavoriteBtnStatic({ seriesId, productId, defaultAdded = false }) {
    const [isLike, setIsLike] = useState(false)

    useEffect(() => {
        setIsLike(defaultAdded)
    }, [defaultAdded]);

    const onAdd = async () => {
        try {
            const data = await apiService.addToMyCollections({ seriesId: seriesId, productId: productId });
            console.log(data);
        } catch (error) {
            console.log(error);
        }
    };

    const onDelete = async () => {
        try {
            const data = await apiService.deleteFromMyCollections({ seriesId: seriesId, productId: productId });
            console.log(data);
        } catch (error) {
            console.log(error);
        }
    }

    const handleClick = () => {
        if (isLike) {
            onDelete();
        } else {
            onAdd();
        }
        setIsLike(!isLike);
    }

    return (
        <div className='btn-icontext btn-favorite' onClick={() => handleClick()}>
            {!isLike && <><img className='icon-normal' src={IconTag} alt="" /><span>加入最愛</span></>}
            {isLike && <><img className='icon-normal' src={IconTagActive} alt="" /><span>取消最愛</span></>}
        </div >
    );
}

export default FavoriteBtnStatic;