import React, { useEffect, useState } from "react"
import IconArrowDark from "../../img/arrow_down_dark.svg"
import RTUtils from "../../lib/rt-utils"
import TaiwanCities from "../../lib/tw-cities"

const genderOptions = ["女", "男", "暫不提供"]
const years = RTUtils.generateYearsList()
const cities = TaiwanCities.cities
const familyOptions = ["有小孩", "沒有小孩"]

function AccountBasic({ user, onChange }) {
  const [fullname, setFullname] = useState("")
  const [phone, setPhone] = useState("")
  const [gender, setGender] = useState("")
  const [birthYear, setBirthYear] = useState("")
  const [birthMonth, setBirthMonth] = useState("")
  const [address, setAddress] = useState("")
  const [family, setFamily] = useState("")
  const [kids, setKids] = useState([])
  const [kidYearOptions, setKidYearOptions] = useState([])
  const [months, setMonths] = useState([1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12])

  const handleFamilyChange = (v) => {
    setFamily(v)
    if (v === "沒有小孩") {
      setKids([])
    } else {
      setKids([{ name: "小孩1", year: "", month: "" }])
    }
  }

  const handleKidsCountChange = (e) => {
    const count = e.target.value
    console.log(count)
    const k = [...kids]
    // if count < kids.length, remove the last few kids
    const diff = count - k.length
    if (diff > 0) {
      for (let i = 0; i < diff; i++) {
        k.push({ name: `小孩${i + 1}`, year: "", month: "" })
      }
    } else {
      // if count > kids.length, add the difference
      for (let i = 0; i < -diff; i++) {
        k.pop()
      }
    }
    setKids(k)
  }

  const handleKidBirthYearChange = (index, year) => {
    const k = [...kids]
    k[index].year = year
    setKids(k)
  }

  const handleKidBirthMonthChange = (index, month) => {
    const k = [...kids]
    k[index].month = month
    setKids(k)
  }

  useEffect(() => {
    if (user) {
      setFullname(user.fullName)
      setPhone(user.phoneNumber)
      setGender(user.gender)
      setBirthYear(user.birthYear)
      setBirthMonth(user.birthMonth)
      setAddress(user.address)
      setFamily(user.family)
      setKids(user.kids)
    }
    // init years: 0-17 years old from current year
    const currentYear = new Date().getFullYear()
    const years = []
    for (let i = 0; i < 18; i++) {
      years.push(currentYear - i)
    }
    setKidYearOptions(years)
  }, [user])

  useEffect(() => {
    const handleOnChange = () => {
      if (user) {
        const u = user
        u.fullName = fullname
        u.phoneNumber = phone
        u.gender = gender
        u.birthYear = birthYear
        u.birthMonth = birthMonth
        u.address = address
        u.family = family
        u.kids = kids
        onChange(u)
      }
    }

    handleOnChange()
  }, [address, birthMonth, birthYear, family, fullname, gender, phone, kids, onChange, user])

  return (
    <div className="account_setting basic">
      <h2>基本設定</h2>
      <div className="row">
        <div className="col-lg-6">
          <div className="account_setting_input">
            <label htmlFor="">姓名</label>
            <div className="account_setting_input_right">
              <input
                className="input-common"
                type="text"
                placeholder="請填寫"
                value={fullname}
                onChange={({ target }) => setFullname(target.value)}
              />
            </div>
          </div>
          <div className="account_setting_input">
            <label htmlFor="">手機</label>
            <div className="account_setting_input_right">
              <input
                className="input-common"
                type="phone"
                placeholder="請填寫"
                value={phone}
                onChange={({ target }) => setPhone(target.value)}
              />
            </div>
          </div>
          <div className="account_setting_input birthday">
            <label htmlFor="">生日</label>
            <div className="account_setting_input_right birthday">
              <div className="input year">
                <div className="input_content"></div>
                <div className="input_btn">
                  <img src={IconArrowDark} alt="" />
                </div>
                <select
                  className="input_select"
                  value={birthYear}
                  onChange={({ target }) => setBirthYear(target.value)}
                >
                  <option value="" disabled>
                    年
                  </option>
                  {years.map((year) => (
                    <option value={year} key={year}>
                      {year}年
                    </option>
                  ))}
                </select>
              </div>
              <div className="input month">
                <div className="input_content"></div>
                <div className="input_btn">
                  <img src={IconArrowDark} alt="" />
                </div>
                <select
                  className="input_select"
                  value={birthMonth}
                  onChange={({ target }) => setBirthMonth(target.value)}
                >
                  <option value="" disabled>
                    月
                  </option>
                  {months.map((m) => (
                    <option value={m} key={m}>
                      {m}月
                    </option>
                  ))}
                </select>
              </div>
            </div>
          </div>
          <div className="account_setting_input">
            <label htmlFor="">縣市</label>
            <div className="account_setting_input_right">
              <div className="input">
                <div className="input_content"></div>
                <div className="input_btn">
                  <img src={IconArrowDark} alt="" />
                </div>
                <select
                  className="input_select"
                  value={address}
                  onChange={({ target }) => setAddress(target.value)}
                >
                  <option value="" disabled>
                    請選擇
                  </option>
                  {cities.map((a) => (
                    <option value={a} key={a}>
                      {a}
                    </option>
                  ))}
                </select>
              </div>
            </div>
          </div>
          <div className="account_setting_input gender">
            <label htmlFor="">性別</label>
            <div className="account_setting_input_right">
              <div className="input">
                <div className="input_content"></div>
                <div className="input_btn">
                  <img src={IconArrowDark} alt="" />
                </div>
                <select
                  className="input_select"
                  value={gender}
                  onChange={({ target }) => setGender(target.value)}
                >
                  <option value="" disabled>
                    請選擇
                  </option>
                  {genderOptions.map((a) => (
                    <option value={a} key={a}>
                      {a}
                    </option>
                  ))}
                </select>
              </div>
            </div>
          </div>
        </div>
        <div className="col-lg-6">
          <div className="account_setting_input">
            <label htmlFor="">家庭狀況</label>
            <div className="account_setting_input_right">
              <div className="input">
                <div className="input_content"></div>
                <div className="input_btn">
                  <img src={IconArrowDark} alt="" />
                </div>
                <select
                  className="input_select"
                  value={family}
                  onChange={({ target }) => handleFamilyChange(target.value)}
                >
                  <option value="" disabled>
                    請選擇
                  </option>
                  {familyOptions.map((a) => (
                    <option value={a} key={a}>
                      {a}
                    </option>
                  ))}
                </select>
              </div>
            </div>
          </div>
          {family === "有小孩" && (
            <div className="account_setting_input">
              <label htmlFor="">孩童數量<span className="lebel_remark">0-17歲</span></label>
              <div className="account_setting_input_right">
                <div className="input">
                  <div className="input_content"></div>
                  <div className="input_btn">
                    <img src={IconArrowDark} alt="" />
                  </div>
                  <select
                    className="input_select"
                    value={kids.length}
                    onChange={(e) => handleKidsCountChange(e)}
                  >
                    <option value="" disabled>
                      請選擇
                    </option>
                    {Array.from({ length: 10 }, (_, index) => index + 1).map((a) => (
                      <option value={a} key={a}>
                        {a}
                      </option>
                    ))}
                  </select>
                </div>
              </div>
            </div>)}
          {kids.map((kid, index) => (
            <div className="account_setting_input birthday" key={index}>
              <label htmlFor="">小孩{index + 1}出生年月</label>
              <div className="account_setting_input_right birthday">
                <div className="input year">
                  <div className="input_content"></div>
                  <div className="input_btn">
                    <img src={IconArrowDark} alt="" />
                  </div>
                  <select name="" id="" className="input_select" value={kid.year} onChange={(e) => handleKidBirthYearChange(index, e.target.value)}>
                    {kidYearOptions.map((year) => (
                      <option value={year} key={year}>
                        {year}年
                      </option>
                    ))}
                  </select>
                </div>
                <div className="input month">
                  <div className="input_content"></div>
                  <div className="input_btn">
                    <img src={IconArrowDark} alt="" />
                  </div>
                  <select name="" id="" className="input_select" value={kid.month} onChange={(e) => handleKidBirthMonthChange(index, e.target.value)}>
                    {months.map((m) => (
                      <option value={m} key={m}>
                        {m}月
                      </option>
                    ))}
                  </select>
                </div>
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  )
}

export default AccountBasic
