import { React, useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';

function OrderBuyer({ checkoutInfo, onChange }) {
    return (
        <div className='account_setting'>
            <h2>訂購人資訊</h2>
            <div className="row d-block">
                <div className="col-xl-5 col-lg-7">
                    <div className="account_setting_input">
                        <label htmlFor="">姓名</label>
                        <div className="account_setting_input_right">
                            <input className="input-common" name="buyerName" type="text" placeholder="請填寫" value={checkoutInfo?.basicInfo.buyerName} onChange={(e) => onChange(e)} />
                        </div>
                    </div>
                    <div className="account_setting_input">
                        <label htmlFor="">電話</label>
                        <div className="account_setting_input_right">
                            <input className="input-common" name="buyerPhone" type="text" placeholder="請填寫" value={checkoutInfo?.basicInfo.buyerPhone} onChange={(e) => onChange(e)} />
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default OrderBuyer;