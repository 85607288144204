import { React, useState } from 'react';

import TitleContent from '../component/common/title-content';
import ServiceInform from '../component/content/service-inform';
import ServiceContact from '../component/content/service-contact';

import IconEmail from '../img/icon_mail_yellow.svg';
import IconLine from '../img/icon_line_yellow.svg';
import IconAddress from '../img/icon_address.svg';

const title = "客服中心";
const inform = [
    {
        icon: IconEmail,
        title: "電子信箱",
        content: "grimm_service@grimmpress.com.tw",
    },
    {
        icon: IconLine,
        title: "官方LINE",
        content: "@grimmpress",
    },
    {
        icon: IconAddress,
        title: "地址",
        content: "106 台北市大安區新生南路二段2號3樓",
    },
]


function Service() {
    return (
        <div className='container service'>
            <TitleContent titleData={title} />
            <div className="service_content">
                <ServiceInform inform={inform} />
                <ServiceContact />
            </div>
        </div>
    )
}

export default Service;