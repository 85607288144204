import { React, useEffect, useState } from 'react';
import { Link } from "react-router-dom";
import PubSub from "pubsub-js"

import IconArrow from "../../img/icon_arrow_left.svg"

function handleActive(event) {
    const sideChapter = document.getElementById("classroom_chapter");
    if (sideChapter.classList.value.includes("active"))
        sideChapter.classList.remove('active');
    else
        sideChapter.classList.toggle('active');
}

function openSideChapter() {
    const sideChapter = document.getElementById("classroom_chapter");
    sideChapter.classList.add('active');
}

function ClassroomChapter({ chapter, lessonId, onSelectLesson }) {
    const [currentLessonId, setCurrentLessonId] = useState(lessonId);

    const handleSelectLesson = async (lessonId) => {
        setCurrentLessonId(lessonId);
        await onSelectLesson(lessonId);
    }

    useEffect(() => {
        setCurrentLessonId(lessonId);
        // scroll to active lesson
        const activeLesson = document.querySelector('.classroom_chapter_item .active');
        if (activeLesson) {
            activeLesson.scrollIntoView({ behavior: 'smooth', block: 'center' });
        }
        openSideChapter();
    }, [lessonId])

    return (
        <div id="classroom_chapter" className='classroom_chapter'>
            <div className="classroom_chapter_btn" onClick={handleActive}>
                <img src={IconArrow} alt="" />
                <p>課<br></br>程<br></br>章<br></br>節</p>
            </div>
            <div className="classroom_chapter_content">
                {
                    chapter.map((data, index) => (
                        <div className="classroom_chapter_item" key={index + 1}>
                            <h2>{data.title}</h2>
                            <ul>
                                {
                                    data?.lessons?.map((lesson, index) => (
                                        <li key={`lesson-${index + 1}`}>
                                            <Link className={lesson.id === currentLessonId ? "active" : ""} onClick={() => handleSelectLesson(lesson.id)}>
                                                <span className="classroom_chapter_name">{lesson.section}：{lesson.name}</span>
                                                <span className="classroom_chapter_time">{lesson.contentLength}</span>
                                            </Link>
                                        </li>
                                    ))
                                }
                            </ul>
                        </div>
                    ))
                }
            </div>
        </div>
    )
}

export default ClassroomChapter;