import React from 'react';
import { Link } from "react-router-dom";
import useUserStatus from '../../hooks/useUserStatus';
import PubSub from "pubsub-js"

function FooterNav() {
    const isLoggedIn = useUserStatus("")
    const handleClick = (e) => {
        if (!isLoggedIn) {
            e.preventDefault()
            PubSub.publish("REQUIRE_LOGIN", e.target.to)
        }
    }
    return (
        <nav className='footer_nav row d-flex justify-content-lg-end justify-content-center'>
            <div className='col-3'>
                <div className="footer_nav_item">
                    <h4>認識我們</h4>
                    <ul>
                        <li><Link to="/about">關於格林</Link></li>
                        <li><Link to="/privacy">隱私權政策</Link></li>
                    </ul>
                </div>
            </div>
            <div className='col-3'>
                <div className="footer_nav_item">
                    <h4>聯絡我們</h4>
                    <ul>
                        <li><Link to="/qa">常見問與答</Link></li>
                        <li><Link to="/service">客服中心</Link></li>
                    </ul>
                </div>
            </div>
            <div className='col-3'>
                <div className="footer_nav_item">
                    <h4>會員專區</h4>
                    <ul>
                        <li><Link to="/order" onClick={handleClick}>我的訂單</Link></li>
                        <li><Link to="/term">服務條款</Link></li>
                    </ul>
                </div>
            </div>
        </nav>
    );
}

export default FooterNav;