import React, { useEffect, useState } from 'react';

import topImage from '../img/tellypen-instruction1.jpg';
import bottomImage from '../img/tellypen-instruction2.jpg';
import topImageMobile from '../img/tellypen-instruction1-m.jpg';
import bottomImageMobile from '../img/tellypen-instruction2-m.jpg';

function TellypenInstructions() {
    const [isMobile, setIsMobile] = useState(false);

    useEffect(() => {
        const handleResize = () => {
            setIsMobile(window.innerWidth <= 768);
        };

        window.addEventListener('resize', handleResize);
        handleResize(); // Check initial screen size

        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, []);

    return (
        <div
            className=""
            style={{
                backgroundColor: 'white',
                paddingLeft: !isMobile ? '4rem' : '0',
                paddingRight: !isMobile ? '4rem' : '0',
                paddingBottom: !isMobile ? '2rem' : '0'
            }}>
            {' '}
            <img src={isMobile ? topImageMobile : topImage} alt="點讀音檔下載說明" style={{ width: '100%' }} />
            <img src={isMobile ? bottomImageMobile : bottomImage} alt="點讀筆按鍵功能說明" style={{ width: '100%', marginTop: '-6px' }} />
        </div>
    );
}

export default TellypenInstructions;
