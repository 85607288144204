import React from 'react';
import { useNavigate } from 'react-router';

import IconArrow from '../../img/icon_arrow-small-deep.svg'

function ArticlePagination({prevNext, detailLink='/read-inner'}){
  const navigate = useNavigate()
  const nonoStyle = {opacity: 0.5, pointerEvents: 'none'}
  const goReadId = (e, id) => {
    e.preventDefault()
    if (id === 0) return
    navigate(`${detailLink}/${id}`)
  }
    return (
        <div className='container'>
            <div className='article_pagination article_pagination_top d-flex align-items-center justify-content-md-between justify-content-center' style={{'borderTop': '0px'}}>
                <a onClick={(e) => goReadId(e, prevNext.prevId)} href="/" className='btn-icontext btn-page prev d-md-flex d-none' style={prevNext.hasPrev?{}:nonoStyle}>
                    <img src={IconArrow} alt=""/>
                    <span>上一篇</span>
                </a>               
                {/* <ul className='d-flex align-items-center justify-content-center'>
                    <li><a className='pagination_arrow normal prev' href=""><img src={IconArrow} alt="" /></a></li>
                    <ul className='pagination_num d-flex align-items-center justify-content-center'>
                        <li><a href="">1</a></li>
                        <li><a href="">2</a></li>
                        <li><a href="">3</a></li>
                        <li><a href="">4</a></li>
                        <li><a className='active' href="">5</a></li>
                    </ul>
                    <li><a className='pagination_arrow normal next' href=""><img src={IconArrow} alt="" /></a></li>
                </ul> */}
                <a onClick={(e) => goReadId(e, prevNext.nextId)} href="/" className='btn-icontext btn-page next d-md-flex d-none' style={prevNext.hasNext?{}:nonoStyle}>
                    <span>下一篇</span>
                    <img src={IconArrow} alt="" />
                </a>
            </div>
            <div className='article_pagination article_pagination_bottom d-md-none d-flex align-items-center justify-content-between'
            >
                <a onClick={(e) => goReadId(e, prevNext.prevId)} href="/" className='btn-icontext btn-page prev' style={prevNext.hasPrev?{}:nonoStyle}>
                    <img src={IconArrow} alt="" />
                    <span>上一篇</span>
                </a>
                <a onClick={(e) => goReadId(e, prevNext.nextId)} href="/" className='btn-icontext btn-page next' style={prevNext.hasNext?{}:nonoStyle}>
                    <span>下一篇</span>
                    <img src={IconArrow} alt="" />
                </a>
            </div>
        </div>
    );
}

export default ArticlePagination;