import React from 'react';

import FavoriteBtn from '../common/favorite-btn';
import SocialShare from '../content/social-share';


function ArticleContentTitle({product}){
    return (
        <div className='d-flex flex-wrap justify-content-between container align-items-start'>
            <div className="article_title">
                <h1>{product.name}</h1>
                <p>文 / {product.author}</p>
            </div>
            <div className="article_btn d-flex align-items-center">
                <SocialShare url={`https://www.readtoday.com.tw/share.php?id=${product.id}`} />
                <FavoriteBtn product={product} />
            </div>
        </div>
    );
}

export default ArticleContentTitle;