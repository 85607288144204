import { React, useState } from 'react';

import LessonTabIntro from './lesson-tab-intro';
import LessonChapter from './lesson-chapter';
import LessonQa from './lesson-qa';
import LessonMessage from './lesson-tab-message';
import LessonAnnounce from './lesson-tab-announce';

function LessonTab({ seriesId, intro, TabChapter, TabQa, TabAnnounce }) {
    const [toggleState, setToggleState] = useState(1);
    const toggleTab = (index) => {
        setToggleState(index);
    }
    return (
        <div className='lesson_content_item'>
            <ul className="lesson_tab d-flex justify-content-center">
                <li className={toggleState === 1 ? 'active' : ''} onClick={() => toggleTab(1)}>簡介</li>
                <li className={toggleState === 2 ? 'active' : ''} onClick={() => toggleTab(2)}>章節</li>
                <li className={toggleState === 3 ? 'active' : ''} onClick={() => toggleTab(3)}>問答</li>
                <li className={toggleState === 4 ? 'active' : ''} onClick={() => toggleTab(4)}>留言</li>
                <li className={toggleState === 5 ? 'active' : ''} onClick={() => toggleTab(5)}>公告</li>
            </ul>
            <div>
                <div className={toggleState === 1 ? 'lesson_tab_content active' : 'lesson_tab_content'}><LessonTabIntro intro={intro} /></div>
                <div className={toggleState === 2 ? 'lesson_tab_content active' : 'lesson_tab_content'}><LessonChapter chapter={TabChapter} /></div>
                <div className={toggleState === 3 ? 'lesson_tab_content active' : 'lesson_tab_content'}><LessonQa qa={TabQa} /></div>
                <div className={toggleState === 4 ? 'lesson_tab_content active' : 'lesson_tab_content'}><LessonMessage seriesId={seriesId} /></div>
                <div className={toggleState === 5 ? 'lesson_tab_content active' : 'lesson_tab_content'}><LessonAnnounce announce={TabAnnounce} /></div>
            </div>
        </div>
    )
}

export default LessonTab;