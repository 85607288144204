import React from 'react';

import FooterNav from '../component/footer/footer_nav';
import FooterSocial from '../component/footer/footer_social';

import FooterLogo from '../img/logo-big.svg';


function Footer(){
    return (
        <footer className="footer">
            <div className="container">
                <div className="footer_top d-lg-flex justify-content-lg-between justify-content-center">
                    <img className='footer_logo' src={FooterLogo} alt="" />
                    <FooterNav />
                </div>
                <div className="d-flex flex-wrap align-items-center justify-content-between">
                    <p className='footer_copyright order-lg-1 order-2'>
                        Copyright © 2022 Grimm Press, Ltd. All Rights Reserved<br></br>
                        格林文化事業股份有限公司 | 84138253
                    </p>
                    <FooterSocial />
                </div>
            </div>
        </footer>
    );
}

export default Footer;