import React, { useEffect, useState } from "react"
import { useNavigate, useParams } from "react-router-dom"

import Video from "../component/content/video"
import ContentText from "../component/content/content_text"
import InnerIcon from "../component/content/inner-icon"
import apiService from "../service/apiService"
import constants from "../lib/constants"
import HeaderTags from "../component/header/header-tags"

function WatchInner() {
  const navigate = useNavigate()
  const { id } = useParams()

  const [product, setProduct] = useState({})

  useEffect(() => {
    const fetchData = async () => {
      try {
        const product = await apiService.apiProduct({ id: id })
        // console.log(product)
        if (product.productType === constants.productType.watch) {
          setProduct(product)
        } else {
          navigate("/")
        }
      } catch (e) {
        if (e.response.status === 403) {
          // unauthorized or no product for this id
          navigate("/member-record?redeem=true")
        } else if (e.response.status === 404) {
          navigate("/")
        } else {
          // display error
          console.log(e.response)
          navigate("/")
        }
      }
    }
    fetchData()
  }, [id, navigate])
  return (
    <div>
      <HeaderTags
        title={product.name}
        description={product.description}
        imageUrl={`${constants.imageBaseUrl}/product/${product.imageMobile}`}
      />    
      <Video product={product} />
      <InnerIcon backLink={'/watch'} product={product} />
      <div className="inner_content position-relative">
        {/* <div className="container">
          <div className="d-md-flex justify-content-end d-none">
            <FavoriteBtn  product={product}/>
          </div>
        </div> */}
        <ContentText product={product} />
      </div>
    </div>
  )
}

export default WatchInner
