import { React, useState } from 'react';

function ServiceContact({ inform }) {
    return (
        <div className='account_setting'>
            <h2>連繫表單</h2>
            <div className="service_contact">
                <div className="row">
                    <div className="col-lg-6">
                        <div className="account_setting_input">
                            <label htmlFor="">您的姓名</label>
                            <div className="account_setting_input_right">
                                <input className="input-common" type="text" placeholder="必填" required />
                            </div>
                        </div>
                    </div>
                    <div className="col-lg-6">
                        <div className="account_setting_input">
                            <label htmlFor="">電子信箱</label>
                            <div className="account_setting_input_right">
                                <input className="input-common" type="text" placeholder="必填" required />
                            </div>
                        </div>
                    </div>
                    <div className="col-lg-12">
                        <textarea name="" id="" cols="30" rows="10" placeholder='請在此詳述問題或者建議'></textarea>
                    </div>
                </div>
                <button className="btn-common">確認送出</button>
                <p className='mt-3 text-center'>請確認聯絡信箱輸入正確，並盡量避免使用Yahoo信箱，以免被視為垃圾信件阻擋。</p>
            </div>
        </div>
    );
}

export default ServiceContact;