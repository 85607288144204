import { React, useState } from "react"

import FilterSearch from "../../component/common/filter_search"
// import FilterMonth from '../../component/common/filter_month';
import FilterArrange from "../../component/common/filter_arrange"
import PopupCommon from "../popup/popup-common"

import IconFilter from "../../img/icon_filter.svg"
import FilterMonth from "./filter_month"
import FilterType from "./filter_type"

function FilterIcon({
  hide = false,
  category,
  keyword,
  order,
  onChangeKeyword,
  onChangeOrder,
  onChangeMonth = undefined,
  onChangeType,
}) {
  const [buttonFilter, setButtonFilter] = useState(false)
  const onChange = (func, val) => {
    func(val)
    setButtonFilter(false)
  }
  return hide ? (
    ""
  ) : (
    <div>
      <button className="btn-icon" onClick={() => setButtonFilter(true)}>
        <img src={IconFilter} alt="" />
      </button>
      <PopupCommon trigger={buttonFilter} setTrigger={setButtonFilter}>
        <h5 className="popup-common_title">搜尋與調整</h5>
        {onChangeMonth && (
          <div className="mb-4">
            <FilterMonth onChange={(val) => onChange(onChangeMonth, val)} />
          </div>
        )}
        <div className="mb-4">
          <FilterSearch keyword={keyword} onChange={(val) => onChange(onChangeKeyword, val)} />
        </div>
        {onChangeType && (
          <div className="mb-4">
            <FilterType onChange={(val) => onChange(onChangeType, val)} />
          </div>
        )}
        <div className="mb-4">
          <FilterArrange
            category={category}
            order={order}
            onChange={(val) => onChange(onChangeOrder, val)}
          />
        </div>
      </PopupCommon>
    </div>
  )
}

export default FilterIcon
