import React from 'react';

import IconLesson from '../../img/icon_lesson.svg';
import IconCollect from '../../img/icon_collect.svg';
import IconDiscount from '../../img/icon_discount.svg';
import IconOrder from '../../img/icon_order.svg';
import IconData from '../../img/icon_data.svg';
import IconExchange from '../../img/icon_exchange.svg';
import { Link } from 'react-router-dom';

const nav = [
    {
        index: 0,
        icon: IconLesson,
        title: "我的課程",
        link: "/member/mylessons"
    },
    {
        index: 1,
        icon: IconCollect,
        title: "我的收藏",
        link: "/member-favorite"
    },
    {
        index: 2,
        icon: IconDiscount,
        title: "我的優惠",
        link: "/member-discount"
    },
    {
        index: 3,
        icon: IconOrder,
        title: "我的訂單",
        link: "/member-record-static-edit"
    },
    {
        index: 4,
        icon: IconData,
        title: "我的資料",
        link: "/member-account-static-edit"
    },
    {
        index: 5,
        icon: IconExchange,
        title: "課程兌換",
        link: "/member-record"
    },
]


function MemberNav({ active }) {
    const activeIndex = active;
    return (
        <div className="member_nav">
            <ul>
                {
                    nav.map((data, index) => (
                        <li key={index}>
                            <Link to={data.link} className={activeIndex === data.index ? "d-flex align-items-center active" : "d-flex align-items-center"}>
                                <img src={data.icon} alt="" />
                                <span>{data.title}</span>
                            </Link>
                        </li>
                    ))
                }
            </ul>
        </div>
    );
}

export default MemberNav;