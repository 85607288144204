const RTUtils = {
  generateYearsList: () => {
    const max = new Date().getFullYear()
    const min = max - 100;
    let years = []
    for (let i = max; i >= min; i--) {
      years.push(i)
    }
    return years
  },
  validateEmail: (email) => {
    return String(email)
      .toLowerCase()
      .match(
        /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
      );
  },
  validatePassword: (password) => {
    // const regex = /^(?=.*\d)(?=.*[a-z])(?=.*[A-Z]).{6,20}$/;
    const regex = /^[A-Za-z0-9]{6,20}$/;
    return password.match(regex)
  },
  validateFullName: (name) => {
    return name.length > 1
  },
  validateMobilePhoneTW: (phone) => {
    return phone.match(/^(09)[0-9]{8}$/)
  },
  minutesToHoursAndMinutes: (minutes) => {
    const hours = Math.floor(minutes / 60);
    const remainMinutes = minutes % 60;
    return [hours, remainMinutes]
  },
  timeTillDateTimeStr: (salesEnd) => {
    const now = new Date();
    const salesEndDate = new Date(salesEnd);
    const diff = salesEndDate - now;
    const days = Math.floor(diff / (1000 * 60 * 60 * 24));
    const hours = Math.floor((diff % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60));
    const minutes = Math.floor((diff % (1000 * 60 * 60)) / (1000 * 60));
    const seconds = Math.floor((diff % (1000 * 60)) / 1000);
    return [days, hours, minutes, seconds];
  },
  formatTimeRemaining: (endDate) => {
    const [days, hours, minutes, seconds] = RTUtils.timeTillDateTimeStr(endDate);
    let timeRemaining = '';

    if (days > 0) {
      timeRemaining += `${days}天`;
    }

    if (hours > 0) {
      timeRemaining += `${hours}時`;
    }

    if (minutes > 0) {
      timeRemaining += `${minutes}分`;
    }

    if (seconds > 0) {
      timeRemaining += `${seconds}秒`;
    }
    return timeRemaining;
  },
}

export default RTUtils;