import React from "react"

import IconArrowPage from "../../img/icon_arrow-page.svg"
import IconArrow from "../../img/icon_arrow-small-deep.svg"

function Pagination({ pagination, onSelectPage }) {
  let pages = []
  let beginPage = pagination.pageNumber < 3 ? 1 : pagination.pageNumber - 2
  for (let i = beginPage; i < beginPage + 5 && i <= pagination.totalPages; i++) {
    const activeClass = i === pagination.pageNumber ? "active" : ""
    pages.push(
      <li key={i}>
        <a
          className={activeClass}
          href="/"
          onClick={(e) => {
            e.preventDefault()
            onSelectPage(i)
          }}
        >
          {i}
        </a>
      </li>
    )
  }
  return (
    <nav className="pagination">
      <ul className="d-flex align-items-center justify-content-center">
        <li>
          <a className="pagination_arrow extreme prev" href="/" onClick={(e) => {
            e.preventDefault()
            if (pagination.pageNumber !== 1) {
              onSelectPage(1)
            }            
          }}>
            <img src={IconArrowPage} alt="" />
          </a>
        </li>
        <li>
          <a className="pagination_arrow normal prev" href="/" onClick={(e) => {
            e.preventDefault()
            if(pagination.pageNumber > 1) {
              onSelectPage(pagination.pageNumber - 1)
            }            
          }}>
            <img src={IconArrow} alt="" />
          </a>
        </li>
        <ul className="pagination_num d-flex align-items-center justify-content-center">
          {pages}
        </ul>
        <li>
          <a className="pagination_arrow normal next" href="/" onClick={(e) => {
            e.preventDefault()
            if (pagination.pageNumber < pagination.totalPages) {
              onSelectPage(pagination.pageNumber + 1)
            }
            
          }}>
            <img src={IconArrow} alt="" />
          </a>
        </li>
        <li>
          <a className="pagination_arrow extreme next" href="/" onClick={(e) => {
            e.preventDefault()
            if(pagination.pageNumber !== pagination.totalPages) {
              onSelectPage(pagination.totalPages)
            }            
          }}>
            <img src={IconArrowPage} alt="" />
          </a>
        </li>
      </ul>
    </nav>
  )
}

export default Pagination
