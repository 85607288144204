import React from 'react';

// import TermItem from '../component/content/term-item';
import TitleContent from '../component/common/title-content';

const title = "隱私權政策"

function Privacy() {
    return (
        <div className='container term'>
            <TitleContent titleData={title} />
            <div className="term_item">
                <h1 className="title_article title_article-third">隱私/著作權聲明</h1>
                <p>格林文化《讀聽格林》網站非常重視會員的隱私權。所以我們制定了一項隱私權政策，載明我們如何收集、利用、揭露、及儲存會員的資料。如會員有任何疑問，請與我們的客服人員聯繫反應。 </p>

                <h1 className="title_article title_article-third">個人資料的收集與利用</h1>
                <p>個人資料，即是該等資料足以識別該個人之資料。在會員與《讀聽格林》網站或格林文化關係企業接觸時，即可能被要求提供個人資料。《讀聽格林》網站與格林文化關係企業可能相互共享本項個人資料，其使用將遵守本隱私權政策。《讀聽格林》網站與其關係企業亦可能將個人資料與其他資料合併，用以提供並改進我們的產品、服務、內容及廣告。有關《讀聽格林》網站可能收集的個人資料，以及可能如何使用這類個人資料，說明如下： </p>
                <br></br>
                <b>個人資料收集項目</b>
                <p>在會員開設一個《讀聽格林》網站 (以下簡稱本服務)帳號、購買本服務所提供之產品、或參與本服務所提供之活動時，我們即可能收集各種資料，包括會員的姓名、生日、通訊地址、電話號碼、電子郵件信箱、購買項目、以及信用卡資料等。若會員參加本服務舉辦之特定活動，我們亦可能請會員提供額外個人資料以進行這些活動。 在會員使用本服務與朋友分享您的內容、寄送產品、或邀請他人參與本服務活動時，《讀聽格林》網站即可能收集您所提供相關人等的資料，諸如姓名、生日、通訊地址、電子郵件信箱、以及電話號碼等。 </p>
                <br></br>
                <b>個人資料的使用方式及項目</b>
                <p>我們所收集的個人資料，使我們得以通知會員有關《讀聽格林》網站及相關企業之最新產品訊息、活動公告、優惠訊息、網站公告、寄送產品。亦可用以協助我們改進我們的服務及內容。若您不希望列載於我們的通訊名單上，您只要通知我們客服人員，即可隨時退出。 我們亦可能為內部目的使用個人資料，例如：稽核、資料分析及研究等，以改進《讀聽格林》網站之產品、服務及客戶溝通。 </p>
                <br></br>
                <b>揭露予第三人</b>
                <p>《讀聽格林》網站遵循「個人資料保護法」之規定，盡力以合理之技術及預防程序，保障所有個人資料之安全，在未經會員同意之下，絕不會將會員的個人資料提供予與本服務無關之第三人。但若依據法律或政府機構的要求，《讀聽格林》網站可能必須揭露會員的個人資料。若我們認為基於法律執行或政府機構的要求之目的揭露會員的資料係屬必要或適當，則我們亦可能在合法的狀況下揭露之。 此外，若發生公司重整、合併或出售情事，我們亦可能移轉任何及全部我們所收集的個人資料予相關第三人。 在會員使用《讀聽格林》網站的某些服務或論壇張貼資料時，會員提供的個人資料會顯示給其他使用者，且其他使用者亦可閱讀、收集或使用之。在此等情況下，會員必須自行對選擇提供的個人資料負責。例如，若將姓名、電話或電子郵件信箱張貼於論壇佈告欄上，該資料即為公開資料。在使用這些功能時，請務必留意。 </p>
                <br></br>
                <b>第三人網站與服務</b>
                <p>《讀聽格林》網站的服務、產品、訊息、論壇、廣告可能含有連結至第三人網站、產品或服務的連結。我們產品與服務亦可能使用或提供第三人產品或服務。第三人所收集的資料，可能包括個人資料，屆時請會員了解這些第三人的隱私權實施方式。</p>
                <br></br>
                <b>隱私權問題</b>
                <p>《讀聽格林》網站得不時更新其隱私權政策。若會員對《讀聽格林》網站隱私權政策有問題或疑慮，請聯絡我們客服人員。 </p>
            </div>
        </div>
    );
}

export default Privacy;