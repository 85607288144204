import axios from "axios"
import constants from "./constants"
import userService from "../service/userService"
/**
 * Create an Axios Client with defaults
 */
const service = axios.create({
  baseURL: constants.api.baseUrl,
  withCredentials: true, // 跨域請求時是否發送cookies
  timeout: 30000 // 請求超時設置  
})

// 請求攔截器
service.interceptors.request.use(config => {
  // 請求前做點什麼？
  if (!config.data) {
    config.data = {}
  }

  // console.log(config)
  // 設置公共參數
  // console.log(qs.stringify(config.data))
  return config
}, error => {
  // 處理請求錯誤
  console.log(error) // 用於調試
  return Promise.reject(error)
})

const clearAndHome = () => {
  userService.clearUser()
  window.location.href = "/"
}

// error handling
const errorHandle = (status, msg) => {
  switch (status) {
    case 401:
      if (msg.message === '帳號或密碼錯誤') {
        return false
      } else {
        clearAndHome()
        return true
      }
    // case 403:
    // clearAndHome()
    // return true
    default:
      console.log(msg)
  }
  return false
}

// 響應攔截器
service.interceptors.response.use(response => {
  // let res = respone.data; // 如果返回的結果是data.data的，嫌麻煩可以用這個，return res
  return response
}, (error) => {
  const { response } = error
  if (response) {
    if (!errorHandle(response.status, response.data.error)) {
      return Promise.reject(response)
    }
  } else {
    if (!window.navigator.onLine) {
      console.log('網路不通，請確認網路連線。')
    }
  }

  return Promise.resolve(response)
})


/**
 * Request Wrapper with default success/error actions
 */
export const go = function (options) {
  const onSuccess = function (response) {
    console.debug('Request Successful!', response);
    return response.data;
  }

  const onError = function (error) {
    console.error('Request Failed:', error.config);

    if (error.response) {
      // Request was made but server responded with something
      // other than 2xx
      console.error('Status:', error.response.status);
      console.error('Data:', error.response.data);
      console.error('Headers:', error.response.headers);

    } else {
      // Something else happened while setting up the request
      // triggered the error
      console.error('Error Message:', error.message);
    }

    return Promise.reject(error.response || error.message);
  }

  return service(options)
    .then(onSuccess)
    .catch(onError);
}

export function fetch(url, params = {}) {
  return new Promise((resolve, reject) => {
    service.get(url, {
      params: params
    }).then(response => {
      if (response)
        resolve(response.data)
      else reject('no data')
    },
      err => {
        reject(err)
      }
    ).catch(err => {
      reject(err)
    })
  })
}

export function post(url, data = {}) {
  // console.log(`POST ${url} --data`)
  // console.log(data)
  return new Promise((resolve, reject) => {
    service.post(url, data).then(
      response => {
        resolve(response.data)
      },
      err => {
        reject(err)
      }
    )
  })
}

export function patch(url, data = {}) {
  // console.log(`PATCH ${url} --data`)
  // console.log(data)
  return new Promise((resolve, reject) => {
    service.patch(url, data).then(
      response => {
        resolve(response.data)
      },
      err => {
        reject(err)
      }
    )
  })
}

export function remove(url, data = {}) {
  return new Promise((resolve, reject) => {
    service.delete(url, data).then(
      response => {
        resolve(response.data)
      },
      err => {
        reject(err)
      }
    )
  })
}

export function put(url, data = {}) {
  return new Promise((resolve, reject) => {
    service.put(url, data).then(
      response => {
        resolve(response.data)
      },
      err => {
        reject(err)
      }
    )
  })
}
const request = {
  get: function (endpoint, paramObj) {
    return fetch(endpoint, paramObj)
  },
  post: function (endpoint, paramObj) {
    return post(endpoint, paramObj)
  },
  put: function (endpoint, paramObj) {
    return put(endpoint, paramObj)
  },
  patch: function (endpoint, paramObj) {
    return patch(endpoint, paramObj)
  },
  delete: function (endpoint, paramObj) {
    return remove(endpoint, paramObj)
  }
}

export default request
