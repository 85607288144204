import {React} from 'react';
import { Link } from "react-router-dom";



function HeaderNav(){
    return (
        <nav className='header_nav ml-4 d-md-block d-none'>
            <ul className='d-flex align-items-center'>
                <li className='header_nav_item'>
                    <Link to="/watch">看</Link>
                </li>
                <li className='header_nav_item'>
                    <Link to="/listen">聽</Link>
                </li>
                <li className='header_nav_item'>
                    <Link to="/read">讀</Link>
                </li>
            </ul>
        </nav>
    );
}

export default HeaderNav;