import React, { useEffect, useState } from "react"
import { useNavigate, useParams } from "react-router-dom"

import InnerBanner from "../component/content/inner-banner"
import Audio from "../component/content/audio"
import ContentTextListen from '../component/content/content_text-listen';
import AudioStyle from "../component/content/audio_style"
import FavoriteBtn from "../component/common/favorite-btn"
import InnerIcon from "../component/content/inner-icon"
import apiService from "../service/apiService"
import constants from "../lib/constants"
import ArticlePagination from "../component/content/article-pagination";
import HeaderTags from "../component/header/header-tags"
import SweetAlert2 from "react-sweetalert2";

function ListenInner() {
  const navigate = useNavigate()
  const { id } = useParams()
  const [swalProps, setSwalProps] = useState({});

  const [product, setProduct] = useState({})
  const [prevNext, setPrevNext] = useState({hasPrev: false, hasNext: false, prevId: 0, nextId: 0})

  const showAlert = (title, text) => {
    setSwalProps({
        show: true,
        title: title,
        text: text,
        icon: 'warning',
        confirmButtonColor: '#FCC800',
        iconColor: '#FCC800',
        onConfirm: () => setSwalProps({ show: false })
    });
}
  useEffect(() => {
    const fetchData = async () => {
      try {
        const product = await apiService.apiProduct({ id: id })
        if (product.productType === constants.productType.listen) {
          setProduct(product)
        } else {
          navigate("/")
        }
        const prevNext = await apiService.apiPrevNext({ id: id })
        // console.log(prevNext)
        setPrevNext(prevNext)        
      } catch (e) {
        if (e.status === 403) {
          // unauthorized or no product for this id
          // navigate("/member-record?redeem=true")
          // 提示 尚未購買此商品
          showAlert('尚未購買此商品', '請先購買此商品')
        } else if (e.status === 404) {
          navigate("/")
        } else {
          // display error
          console.log(e)
          navigate("/")
        }
      }
    }
    fetchData()
  }, [id, navigate])
  return (
    <div>
      <HeaderTags
        title={product.name}
        description={product.description}
        imageUrl={`${constants.imageBaseUrl}/product/${product.imageMobile}`}
      />        
      <InnerBanner product={product} backLink={'/listen'} />
      <div className="inner-box position-relative">
        <InnerIcon backLink={'/listen'} product={product} />
        <div className="d-md-block d-none btn-favorite-box">
          <div className="container">
            <FavoriteBtn  product={product}/>
          </div>
        </div>
        <Audio product={product} />
        <ContentTextListen product={product} />
        <ArticlePagination prevNext={prevNext} detailLink={'/listen-inner'}/>
        <AudioStyle />
      </div>
      <SweetAlert2 {...swalProps} />
    </div>
  )
}

export default ListenInner
