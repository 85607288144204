import { React, useEffect, useState } from 'react';
import { ThreeDots } from 'react-loader-spinner';
import { Link, useNavigate } from 'react-router-dom';

function OrderInformCheck({ checkoutInfo, onPay }) {
    const navigate = useNavigate()
    const [salesPlanDiscount, setSalesPlanDiscount] = useState(0)
    useEffect(() => {
        if (checkoutInfo) {
            const discount = parseInt(checkoutInfo.discount.replace(',', ''))
            const couponDiscount = parseInt(checkoutInfo.couponDiscount.replace(',', ''))
            const promoCodeDiscount = parseInt(checkoutInfo.promoCodeDiscount.replace(',', ''))
            const spDiscount = discount - couponDiscount - promoCodeDiscount
            if (spDiscount > 0) {
                setSalesPlanDiscount(spDiscount)
            }
        }
    }, [checkoutInfo])

    return (
        <div className='order mb-lg-0 mb-5'>
            <h5 className='d-lg-none'>結帳明細</h5>
            <div className="order_content">
                <div className="order_inform_item order_inform_check">
                    <h4>結帳明細</h4>
                    {checkoutInfo && <>
                        <div className="order_list">
                            {checkoutInfo.discount != "0" && <>
                                <div className="d-flex align-items-center justify-content-between mb-2">
                                    <span>原價</span><span className='origin'>NT$ {checkoutInfo.originalPrice}</span>
                                </div>
                                {salesPlanDiscount > 0 &&
                                    <div className="d-flex align-items-center justify-content-between mb-2">
                                        <span>方案折扣</span><span className='discount'>-NT$ {salesPlanDiscount}</span>
                                    </div>}
                                {checkoutInfo.couponDiscount != "0" &&
                                    <div className="d-flex align-items-center justify-content-between mb-2">
                                        <span>優惠券</span><span className='discount'>-NT$ {checkoutInfo.couponDiscount}</span>
                                    </div>}
                                {checkoutInfo.promoCodeDiscount != "0" &&
                                    <div className="d-flex align-items-center justify-content-between mb-2">
                                        <span>折扣碼</span><span className='discount'>-NT$ {checkoutInfo.promoCodeDiscount}</span>
                                    </div>}

                                <hr className='mb-3 mt-3' />

                                <div className="d-flex align-items-center justify-content-between mb-2">
                                    <span>合併折扣</span><span className='discount'>-NT$ {checkoutInfo.discount}</span>
                                </div>
                            </>
                            }
                            <div className="d-flex align-items-center justify-content-between">
                                <span>合計</span><span className='order_price'>NT$ {checkoutInfo.totalPrice}</span>
                            </div>
                        </div>
                        <Link className='order_btn btn-common mb-4' onClick={() => onPay()}>前往付款</Link>
                        <Link className='order_btn btn-border' to={"/lesson"}>繼續選購</Link>
                    </>}{!checkoutInfo && <ThreeDots
                        visible={true}
                        width="50"
                        height={30}
                        color="#4fa94d"
                        radius="9"
                        ariaLabel="three-dots-loading"
                        wrapperStyle={{ display: "flex", justifyContent: "center" }}
                        wrapperClass=""
                    />}
                </div>
            </div>
        </div>
    );
}

export default OrderInformCheck;