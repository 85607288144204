import React from 'react';
import constants from '../../lib/constants';

function ArticleContentInner({product}){
    return (
        <div className='container article_content'>
          {product.imageMobile && <img src={`${constants.imageBaseUrl}/product/${product.imageMobile}`} alt="" />}
          <div dangerouslySetInnerHTML={{ __html: product.contentUri }}></div>
            {/* <h2 className='title_article title_article-second'>得來頁巾得來頁巾</h2>
            <p>
                朵現美的隻冰寸胡正鳥弟習見去至！口幼跟乍住：戊燈公樹魚早也！爸女幸弟手每貝父助消。送誰布事家第出故清友植天衣遠兆麼斥次止冬，害教斤紅有休掃松光頭、六走拉書知珠不瓜跳。室行第丁良飽荷快地收兌京美村京；活兒旁跟你各氣火司戊辛我日書衣者何、往杯爪皮假羽辛室壯婆勿兔古乍坐具。立青冒。

                牠尾爪光止到停，干亭四婆花去工澡多丁固細院家員布動玩；童耍香因住教文師各大急！幼背千火午可把胡足只條穿音肉固？鴨回巾國蝸泉反支，苗汁荷，校雄怎怎帶荷抄哥跟。成王童雞院爸對？福穿祖還冬虎寸收植巴帶田幫，小已旁時寸波蛋耍次几法。午而叫空方兒姊念？比月語天到息目。讀朋雪壯用姐動十，乍姐弟木；訴手原跟成即巴村乙旁即自着筆苦平像己，細飛還穿，只刃條汗世爸實正現蝶，日穴尾帽課司貓問哪象哪足立後沒具風歌！車歌眼春眼呢。

                安而牙能耳哭；高寫要主雨誰。
            </p>
            <img src={Pic} alt="" />
            <h2 className='title_article title_article-second'>得來頁巾得來頁巾</h2>
            <p>
                朵現美的隻冰寸胡正鳥弟習見去至！口幼跟乍住：戊燈公樹魚早也！爸女幸弟手每貝父助消。送誰布事家第出故清友植天衣遠兆麼斥次止冬，害教斤紅有休掃松光頭、六走拉書知珠不瓜跳。室行第丁良飽荷快地收兌京美村京；活兒旁跟你各氣火司戊辛我日書衣者何、往杯爪皮假羽辛室壯婆勿兔古乍坐具。立青冒。

                牠尾爪光止到停，干亭四婆花去工澡多丁固細院家員布動玩；童耍香因住教文師各大急！幼背千火午可把胡足只條穿音肉固？鴨回巾國蝸泉反支，苗汁荷，校雄怎怎帶荷抄哥跟。成王童雞院爸對？福穿祖還冬虎寸收植巴帶田幫，小已旁時寸波蛋耍次几法。午而叫空方兒姊念？比月語天到息目。讀朋雪壯用姐動十，乍姐弟木；訴手原跟成即巴村乙旁即自着筆苦平像己，細飛還穿，只刃條汗世爸實正現蝶，日穴尾帽課司貓問哪象哪足立後沒具風歌！車歌眼春眼呢。

                安而牙能耳哭；高寫要主雨誰。
            </p> */}
        </div>
    );
}

export default ArticleContentInner;