import React from 'react';

function Title({data}){
    return (
        <div className="d-flex align-items-center">
            <img src={data.pic} alt="" />
            <div>
                <h1>{data.name}</h1>
                <p>{data.parent}</p>
            </div>
        </div>
    );
}

export default Title;