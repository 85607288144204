import React, { useEffect, useState } from 'react';

import Title from '../component/common/title';
import MemberNav from '../component/member/member-nav';
import PaginationStatic from '../component/common/pagination-static';
import Discount from '../component/member/discount';

import iconHead from '../img/icon_head.svg';
import IconEmpty from '../img/icon_empty.svg';
import apiService from '../service/apiService';

const PageTitle = { pic: iconHead, name: "我的優惠", parent: "會員專區" }
const NavActiveIndex = 2;

function MemberDiscount() {
    const [content, setContent] = useState([])
    const [page, setPage] = useState(1)
    const [totalPages, setTotalPages] = useState(1)
    const [loading, setLoading] = useState(false)
    const [reload, setReload] = useState(true)

    const handlePrev = () => {
        if (page === 1) return
        setPage(page - 1)
    }

    const handleNext = () => {
        if (page === totalPages) return
        setPage(page + 1)
    }

    const handleFirst = () => {
        setPage(1)
    }

    const handleLast = () => {
        setPage(totalPages)
    }

    const handlePageChange = (page) => {
        setPage(page)
    }

    useEffect(() => {
        const fetchData = async () => {
            setLoading(true)
            try {
                const myDiscount = await apiService.getCoupons()
                setContent(myDiscount.content)
                setPage(myDiscount.pageNumber)
                setTotalPages(myDiscount.totalPages)
            } catch (e) {
                console.log(e)
            } finally {
                setLoading(false)
                setReload(false)
            }
        }
        if (reload) {
            fetchData()
        }
    }, [reload])

    const handleUseCoupon = async (couponId) => {
        try {
            const coupon = content.find(c => c.id === couponId)
            if (coupon.isInUse) {
                await apiService.unuseCoupon({ id: couponId })
            } else {
                await apiService.useCoupon({ id: couponId })
            }
            setReload(true)
        } catch (error) {
            console.log(error)
        }
    }

    return (
        <div className='container'>
            <div className="title_content title-member">
                <Title data={PageTitle} />
            </div>
            <div className="member_page page">
                <div className="row">
                    <div className="col-xl-3 col-lg-4 mb-4">
                        <MemberNav active={NavActiveIndex} />
                    </div>
                    <div className="col-xl-9 col-lg-8">
                        <div className="member_content">
                            <div className="row">
                                {content && content.length > 0 &&
                                    content.map((data, index) => (
                                        <div className="col-lg-6" key={index}>
                                            <Discount discount={data} onUseCoupon={handleUseCoupon} />
                                        </div>
                                    ))
                                }
                            </div>
                            {!content || content.length === 0 &&
                                <div className="member_empty">
                                    <img src={IconEmpty} alt="" />
                                    <p>這裡空空的...</p>
                                </div>
                            }
                        </div>
                        {content && content.length > 0 &&
                            <PaginationStatic
                                page={page}
                                totalPages={totalPages}
                                onPrev={handlePrev}
                                onNext={handleNext}
                                onFirst={handleFirst}
                                onLast={handleLast}
                                onPageChange={handlePageChange}
                            />}
                    </div>
                </div>
            </div>
        </div>
    );
}

export default MemberDiscount;