import { React, useEffect, useState } from 'react';

import Check from '../common/check';

import IconArrowDark from "../../img/arrow_down_dark.svg"

const invoicePersonal = "個人電子發票";
const invoiceCompany = "公司戶電子發票 / 統一編號";

function OrderInvoice({ checkoutInfo, onChange }) {
    const [infoPersonal, setInfoPersonal] = useState('')
    const [infoCompany, setInfoCompany] = useState('')

    const onInvoiceTypeChange = (value) => {
        if (!checkoutInfo) return
        onChange({ target: { name: 'invoiceType', value } })
    }

    const onInvoiceInfoChange = (value) => {
        if (!checkoutInfo) return
        onChange({ target: { name: 'invoiceInfo', value } })
    }

    useEffect(() => {
        if (!checkoutInfo) return
        const invoiceInfo = checkoutInfo?.basicInfo?.invoiceInfo || '';
        setInfoPersonal(checkoutInfo.basicInfo.invoiceType == 1 ? invoiceInfo : '')
        setInfoCompany(checkoutInfo.basicInfo.invoiceType == 2 ? invoiceInfo : '')
    }, [checkoutInfo])

    const onInfoPersonalChange = (v) => {
        v = v.toUpperCase();
        setInfoPersonal(v)
        if (checkoutInfo.basicInfo.invoiceType == 1) {
            onInvoiceInfoChange(v)
        }
    }

    const onInfoCompanyChange = (v) => {
        setInfoCompany(v)
        if (checkoutInfo.basicInfo.invoiceType == 2) {
            onInvoiceInfoChange(v)
        }
    }

    return (
        <div className='account_setting'>
            <h2>發票資訊</h2>
            <div>
                <div className="invoice_item">
                    <Check data={invoicePersonal} onChange={(checked) => onInvoiceTypeChange(checked ? 1 : 2)} checked={checkoutInfo?.basicInfo.invoiceType == 1} />
                    <div className="invoice_content">
                        <div className="row">
                            <div className="col-xl-4 col-lg-5">
                                <div className="input">
                                    <div className="input_content"></div>
                                    <div className="input_btn">
                                        <img src={IconArrowDark} alt="" />
                                    </div>
                                    <select name="invoiceCarrier" id="invoiceCarrier" className="input_select" onChange={(e) => onChange(e)} value={checkoutInfo?.basicInfo.invoiceCarrier}>
                                        <option value="1">會員載具</option>
                                        <option value="2">手機條碼</option>
                                        <option value="3">自然人憑證</option>
                                    </select>
                                </div>
                            </div>
                            <div className="col-xl-4 col-lg-5">
                                <input className="input-common" type="text" placeholder="請填寫" value={infoPersonal} onChange={(e) => onInfoPersonalChange(e.target.value)} />
                            </div>
                        </div>
                        <ul className='invoice_remark'>
                            <li>*無輸入載具條碼時，以email寄送雲端發票。</li>
                            <li>*配合國稅局勸止二聯換開三聯之政策，本公司保留換開發票之權利。</li>
                        </ul>
                    </div>
                </div>
                <div className="invoice_item">
                    <Check data={invoiceCompany} onChange={(checked) => onInvoiceTypeChange(checked ? 2 : 1)} checked={checkoutInfo?.basicInfo.invoiceType == 2} />
                    <div className="invoice_content">
                        <div className="row">
                            <div className="col-xl-5 col-lg-7">
                                <input className="input-common" type="text" placeholder="請填寫" value={infoCompany} onChange={(e) => onInfoCompanyChange(e.target.value)} />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default OrderInvoice;