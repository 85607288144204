import React from "react"
import { Link } from "react-router-dom"
import constants from "../../lib/constants"

function ListItem({ content, detailLink }) {
  return (
    <Link to={`${detailLink}/${content.id}`} className="d-flex list_content_item">
      <div className="list_content_pic">
        <img src={`${constants.imageBaseUrl}/product/${content.image}`} alt="" />
      </div>
      <div className="list_content_text">
        <p className="list_content_title">{content.name}</p>
        <p className="list_content_date">{content.releaseDate}</p>
      </div>
    </Link>
  )
}

export default ListItem
