import React from 'react';

import TermItem from '../component/content/term-item';
import TitleContent from '../component/common/title-content';

const title = "會員服務條款"

function Term(){
    return (
        <div className='container term'>
            <TitleContent titleData={title} />
            <TermItem />
        </div>
    );
}

export default Term;