import React from 'react';


function ContentTextListen({product}){
    return (
        <div className='container article_content'>
            <h2 className='title_article'>{product.name}</h2>
            <div dangerouslySetInnerHTML={{ __html: product.description }}></div>
            {/* <p>{product.description}</p> */}
        </div>
    );
}

export default ContentTextListen;