import React, { useEffect, useState } from 'react';
import IconVideo from '../../img/icon_video_2.svg';
import constants from '../../lib/constants';

function LessonBanner({ title, description, author, backgroundImage, handleClickScroll }) {
    return (
        <div className='lesson_banner'>
            <div className="lesson_banner_inner">
                <div className="lesson_banner_content" style={{ backgroundImage: `url(${constants.imageBaseUrl}/${backgroundImage})` }}>
                    <div className="lesson_banner_mask"></div>
                    <div className="lesson_banner_video" onClick={handleClickScroll}>
                        <img src={IconVideo} alt="" />
                        <p>觀看介紹影片</p>
                    </div>
                    <div className="lesson_banner_text">
                        <h1>{title}</h1>
                        <p className="lesson_banner_teacher">{author}</p>
                        {/* <p className="lesson_banner_intro">
                            {description}
                        </p> */}
                    </div>
                </div>
            </div>
        </div>
    )
}

export default LessonBanner;