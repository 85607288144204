/**************************************************************************************************************************************************************
 * 最新課程 / 熱門課程 / 限時優惠
 **************************************************************************************************************************************************************/

import React, { useEffect, useState } from 'react';

import Title from '../component/common/title';
import CardLesson from '../component/list/card_lesson';
import PaginationStatic from '../component/common/pagination-static';
import FilterStatic from '../component/common/filter-static';
import FilterIconStatic from '../component/common/filter-icon-static';
import IconLesson from '../img/icon_lesson.svg'
import apiService from '../service/apiService';

function LatestLesson({ type = 'latest' }) {
    const [title, setTitle] = useState('最新課程')
    const [lessons, setLessons] = useState([])
    const [page, setPage] = useState(1)
    const [totalPages, setTotalPages] = useState(1)
    const [loading, setLoading] = useState(false)
    const [keyword, setKeyword] = useState('')
    const [order, setOrder] = useState('asc')

    const loadData = async () => {
        setLoading(true)
        try {
            const params = {
                type: type,
                page: page,
                keyword: keyword,
                order: order
            }
            const data = await apiService.searchLessons(params)
            setLessons(data.content)
        } catch (e) {
        } finally {
            setLoading(false)
        }
    }

    const handlePrev = () => {
        if (page === 1) return
        setPage(page - 1)
    }

    const handleNext = () => {
        if (page === totalPages) return
        setPage(page + 1)
    }

    const handleFirst = () => {
        setPage(1)
    }

    const handleLast = () => {
        setPage(totalPages)
    }

    const handlePageChange = (page) => {
        setPage(page)
    }

    useEffect(() => {
        switch (type) {
            case 'latest':
                setTitle('最新課程')
                break;
            case 'hot':
                setTitle('熱門課程')
                break;
            case 'discount':
                setTitle('限時優惠')
                break;
            default:
                break;
        }
        loadData()
    }, [type, page, keyword, order])

    return (
        <div className='container'>
            <div className="title_content d-flex align-items-center justify-content-between">
                <Title data={{ pic: IconLesson, name: title }} />
                <div className="d-lg-none d-block">
                    <FilterIconStatic />
                </div>
            </div>
            <div className="member_page lesson">
                <div className="row">
                    <div className="col-xl-3 col-lg-4 d-lg-block d-none">
                        <FilterStatic onFilterChange={({ keyword, order }) => {
                            setKeyword(keyword)
                            setOrder(order)
                        }} />
                    </div>
                    <div className="col-xl-9 col-lg-8">
                        <div className="member_content">
                            <div className="row">
                                {
                                    lessons?.map(lesson => (
                                        <div className="col-xl-4 col-md-6" key={lesson.seriesId}>
                                            <CardLesson lessonData={lesson} />
                                        </div>
                                    ))
                                }
                            </div>
                        </div>
                        <PaginationStatic
                            page={page}
                            totalPages={totalPages}
                            onPrev={handlePrev}
                            onNext={handleNext}
                            onFirst={handleFirst}
                            onLast={handleLast}
                            onPageChange={handlePageChange}
                        />
                    </div>
                </div>
            </div>
        </div>
    )
}

export default LatestLesson;