import React, { useState, useEffect } from "react"

import IconTag from "../../img/icon_tag.svg"
import IconTagActive from "../../img/icon_tag-active.svg"
import apiService from "../../service/apiService"

function FavoriteBtn({ product }) {
  const [isLike, setIsLike] = useState(false)

  useEffect(() => {
    setIsLike(product.isLiked)
  }, [product.isLiked])
  

  const params = {
    productId: product.id,
    isLike: !isLike,
  }
  const handleClick = async () => {
    try {
      // const resp = 
      await apiService.apiPostLikes(params)
      setIsLike(params.isLike)
    } catch (e) {
      console.log(e)
    }
  }
  return (
    <div
      className={`btn-icontext btn-favorite ${isLike ? "active" : ""}`}
      onClick={() => handleClick()}
    >
      <img className="icon-normal" src={IconTag} alt="" />
      <img className="icon-active" src={IconTagActive} alt="" />
      <span>{!isLike ? "加入最愛" : "取消最愛"}</span>
    </div>
  )
}

export default FavoriteBtn
