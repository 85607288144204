import React, { useEffect, useState } from "react"

import ListBanner from "../component/list/list-banner"
import ListContent from "../component/list/list-content"
import HeaderListen from "../container/Header-listen"

import iconListen from "../img/icon_listen_color.svg"
import iconHead from "../img/icon_head.svg"

import apiService from "../service/apiService"
import constants from "../lib/constants"
import { Default } from "react-awesome-spinners"

const list = [
  { icon: iconListen, title: "最新音檔", category: "latest" },
  { icon: iconListen, title: "繼續聆聽", category: "history" },
  { icon: iconListen, title: "熱門音檔", category: "hot" },
  { icon: iconHead, title: "我的最愛", category: "like" },
]
function Listen() {
  const [banners, setBanners] = useState([])
  const [latest, setLatest] = useState([])
  const [history, setHistory] = useState([])
  // const [hot, setHot] = useState([]) // temporarily remove, add back after there are more contents
  const [like, setLike] = useState([])
  const [loading, setLoading] = useState(false)

  useEffect(() => {
    const fetchData = async () => {
      setLoading(true)
      try {
        const banners = await apiService.apiHomeBanners()
        setBanners(banners)
        const page = await apiService.apiPage({
          type: constants.productType.listen,
        })
        setLatest(page.latest.content)
        setHistory(page.history.content.map((history) => history.product))
        // setHot(page.hot.content)
        setLike(page.like.content)
      } catch (e) {
      } finally {
        setLoading(false)
      }
    }
    fetchData()
  }, [])

  return (
    <>
      {loading && (
        <div className="loader">
          <Default color="black" />
          <p>讀取中</p>
        </div>
      )}
      {!loading && (
        <div>
          {" "}
          <HeaderListen />
          <ListBanner
            banners={banners.filter((b) => b.location === "LISTEN")}
          />
          <div className="container">
            <ListContent
              section={list[0]}
              contentList={latest}
              showAllLink={"/listen-content"}
              detailLink={"/listen-inner"}
            />
            <ListContent
              section={list[1]}
              contentList={history}
              showAllLink={"/listen-content"}
              detailLink={"/listen-inner"}
            />
            {/* <ListContent section={list[2]} contentList={hot} showAllLink={'/listen-content'} detailLink={'/listen-inner'} /> */}
            <ListContent
              section={list[3]}
              contentList={like}
              showAllLink={"/listen-content"}
              detailLink={"/listen-inner"}
            />
          </div>
        </div>
      )}
    </>
  )
}

export default Listen
