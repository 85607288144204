import React from 'react';
import { Collapse } from 'antd';

import IconArrow from '../../img/icon-arrow-down.svg';
import IconCheck from '../../img/icon_check.svg';

const { Panel } = Collapse;


function handleActive(event) {
    if (event.currentTarget.classList.value.includes("active"))
        event.currentTarget.classList.remove('active');
    else
        event.currentTarget.classList.toggle('active');
}

function LessonChapter({ chapter, mylesson }) {
    const my = mylesson;
    return (
        <div className={my === 1 ? "lesson_chapter lesson_collapse my" : "lesson_chapter lesson_collapse"}>
            <Collapse>
                {
                    chapter.map((data, index) => (
                        <Panel header={
                            <div className="lesson_chapter_show lesson_collapse_show d-flex align-items-center" onClick={handleActive}>
                                <h3 className='lesson_chapter_title'>{data.title}</h3>
                                {
                                    my === 1 ? <div className="lesson_chapter_time">共{data.lessons.length}個單元</div> : <div className="lesson_chapter_time"></div>
                                }
                                {
                                    my === 1 ? <div className="lesson_chapter_finish">是否完成</div> : <div className="lesson_chapter_finish">共{data.lessons.length}個單元</div>
                                }
                                <div className="lesson_collapse_arrow"><img src={IconArrow} alt="" /></div>
                            </div>
                        } key={index + 1}>
                            <div className="lesson_collapse_hide">
                                {
                                    data.lessons.map((ContentData, ContentIndex) => (
                                        <div className="lesson_chapter_hide_item lesson_collapse_hide_item" key={ContentIndex}>
                                            <h4 className='lesson_chapter_title'>
                                                <span className='lesson_chapter_title_num'>{ContentData.section}</span>
                                                <span className='lesson_chapter_title_main'>{ContentData.name}</span>
                                            </h4>
                                            <div className="lesson_chapter_time">{ContentData.contentLength}</div>
                                            {
                                                my === 1 ?
                                                    <div className="lesson_chapter_finish">
                                                        {
                                                            ContentData.isFinished == true ? <img src={IconCheck} alt="" /> : ""
                                                        }
                                                    </div> :
                                                    <div className="lesson_chapter_finish"></div>
                                            }
                                            <div className="lesson_collapse_arrow"></div>
                                        </div>
                                    ))
                                }
                            </div>
                        </Panel>
                    ))
                }
            </Collapse>
        </div>
    )
}

export default LessonChapter;