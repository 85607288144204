import React from 'react';
// import Collapse from '@kunukn/react-collapse'
import { Collapse } from 'antd';

import IconArrow from '../../img/icon-arrow-down.svg';

const { Panel } = Collapse;

function handleActive(event) {
    if (event.currentTarget.classList.value.includes("active"))
        event.currentTarget.classList.remove('active');
    else
        event.currentTarget.classList.toggle('active');
}

function LessonQa({ qa }) {

    return (
        <div className="lesson_qa lesson_collapse">
            <Collapse>
                {
                    qa?.map((data, index) => (
                        <Panel header={
                            <div className="lesson_qa_show lesson_collapse_show d-flex align-items-center" onClick={handleActive}>
                                <h3 className='lesson_qa_title'>Q{index + 1}.{data.question}</h3>
                                <div className="lesson_collapse_arrow"><img src={IconArrow} alt="" /></div>
                            </div>
                        } key={index + 1}>
                            <div className="lesson_qa_hide lesson_collapse_hide">
                                <div dangerouslySetInnerHTML={{ __html: data.answer }} />
                            </div>
                        </Panel>
                    ))
                }
            </Collapse>
        </div>
    )
}

export default LessonQa;