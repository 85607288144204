import {React} from 'react';

import PicCheck from '../../img/pic_check.svg';

function PopupSuccess(props){
    return (props.trigger) ? (
        <div className="popup d-block">
            <div className="popup_inner popup-large popup-hint-box">
                <div className="row">
                    <div className="col-md-6">
                        <div className="popup_logo hint"><img src={PicCheck} alt="" /></div>
                    </div>
                    <div className="col-md-6">
                        <div className="popup_right popup_right-hint">
                            <h3>{props.title}</h3>
                            <p>{props.message}</p>
                            <button className="btn-common btn-hint_check" onClick={() => props.setTrigger(false)}>{props.okLabel}</button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    ) : "";
}

export default PopupSuccess;