import React from 'react';

import Title from '../component/common/title';
import MemberNav from '../component/member/member-nav';
import CardLesson from '../component/list/card_lesson';
import PaginationStatic from '../component/common/pagination-static';

import iconHead from '../img/icon_head.svg';
import IconEmpty from '../img/icon_empty.svg';

const PageTitle = {pic:iconHead, name:"我的優惠"}
const NavActiveIndex = 2;


function MemberEmpty(){
    return (
        <div className='container'>
            <div className="title_content">
                <Title data={PageTitle} />
            </div>
            <div className="member_page page">
                <div className="row">
                    <div className="col-xl-3 col-lg-4">
                        <MemberNav active={NavActiveIndex}/>
                    </div>
                    <div className="col-xl-9 col-lg-8">
                        <div className="member_content">
                            <div className="member_empty">
                                <img src={IconEmpty} alt="" />
                                <p>目前尚無折價券</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default MemberEmpty;