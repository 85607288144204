import { React, useState } from "react"
import apiService from "../../service/apiService"
import RTUtils from "../../lib/rt-utils"
import PopupHint from "./popup-hint-template-1"

function ForgetPassword({ onOk }) {
  const [email, setEmail] = useState("")
  const [errMsg, setErrMsg] = useState("")
  const [showPopup, setShowPopup] = useState(false)

  const handleClick = async (e) => {
    e.preventDefault()
    if (!RTUtils.validateEmail(email)) {
      setErrMsg("請填寫正確的 E-mail")
      return
    }
    try {
      await apiService.apiForgetPassword({
        username: email,
      })
    } catch (e) {
      console.log(e)
    } finally {
      setShowPopup(true)
    }
  }

  return (
    <div className="popup-medium_content">
      <PopupHint
        trigger={showPopup}
        setTrigger={setShowPopup}
        title="發送完成"
        message="如果您輸入的E-mail有註冊為本站會員帳號，您將於E-mail信箱收到一封變更密碼的通知信。"
        onOk={onOk}
      />

      <h5 className="popup_title login">忘記密碼</h5>
      <p className="popup_intro">
        請輸入您的會員帳號，您的會員帳號可能是您常用的E-mail。
      </p>
      <form action="" className="popup_form">
        <input
          className="input-common"
          type="text"
          placeholder="帳號"
          value={email}
          onChange={(e) => setEmail(e.target.value)}
        />
        <button className="btn-common" onClick={handleClick}>
          重設密碼
        </button>
        {errMsg && <p className="popup_hint">{errMsg}</p>}
      </form>
    </div>
  )
}

export default ForgetPassword
