import React from 'react';

function LessonAnnounce({ announce }) {

    return (
        <div>
            {
                announce.map((data, index) => (
                    <div className="lesson_anno" key={index}>
                        <h3 className="lesson_anno_title">{data.title}</h3>
                        <p className="lesson_anno_time">{data.date}</p>
                        <div className="lesson_anno_content">
                            <p>{data.content}</p>
                        </div>
                    </div>
                ))
            }
        </div>
    )
}

export default LessonAnnounce;