import { React, useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';

import IconTag from '../../img/icon_tag.svg';
import IconTagActive from '../../img/icon_tag-active.svg';
import iconCross from "../../img/icon_cross_dark.svg";
import constants from '../../lib/constants';
import { ThreeDots } from 'react-loader-spinner';

// function handleActive(event) {
//     if (event.currentTarget.classList.value.includes("active"))
//         event.currentTarget.classList.remove('active');
//     else
//         event.currentTarget.classList.toggle('active');
// }

function CardCart({ cartData, handleRemove }) {
    const [isLoading, setIsLoading] = useState(false);
    const discount = cartData.price !== cartData.priceSales;

    const onClickRemove = async () => {
        setIsLoading(true);
        handleRemove(cartData.cartId);
    }

    return (
        <div className='cartItem'>
            <Link to={`/lesson-content/${cartData.primarySeriesId}`}>
                <div className="d-flex">
                    <div className="cartItem_pic">
                        <div className="lessonItem_pic"><img src={constants.imageUrl(cartData.image)} alt="" /></div>
                    </div>
                    <div className="cartItem_text">
                        <h3 className="lessonItem_title">{cartData.seriesNames}</h3>
                        <p className="lessonItem_teacher">{cartData.instructors}</p>
                    </div>
                </div>
                <div className="cartItem_bottom">
                    <p className="lessonItem_time">  {cartData.time}</p>
                    <div className="d-flex align-items-center">
                        <h4 className={discount === true ? 'lessonItem_price discount' : "lessonItem_price"}>NT$ {cartData.priceSales}</h4>
                        <span className='lessonItem_origin'>{cartData.origin}</span>
                        <p className={discount === true ? 'cartItem_discountName' : "cartItem_discountName d-none"}>{cartData.name}</p>
                    </div>
                </div>
            </Link>
            <div className="cartItem_btn">
                {/* <div className="btn-icon btn-favorite-m" onClick={handleActive}>
                    <img className='icon-normal' src={IconTag} alt="" />
                    <img className='icon-active' src={IconTagActive} alt="" />
                </div> */}
                {!isLoading && <button className="btn-icon cartItem_cross"
                    onClick={onClickRemove}
                >
                    <img src={iconCross} alt="" />
                </button>}
                {!isLoading && <ThreeDots
                    visible={isLoading}
                    height="30"
                    width="30"
                    color="#4fa94d"
                    radius="9"
                    ariaLabel="three-dots-loading"
                    wrapperStyle={{}}
                    wrapperClass=""
                />}
            </div>
        </div>
    );
}

export default CardCart;