import React from 'react';

import AudioLogo from '../../img/audio_logo.svg';


function AdioStyle(){
    return (
        <div className='audio_style deep'>
            <div className="container d-flex align-items-center justify-content-between">
                <img className='audio_style_logo' src={AudioLogo} alt="" />
                <div className="audio_style_circle"></div>
            </div>
        </div>
    );
}

export default AdioStyle;